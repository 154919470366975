import { useState, VFC } from "react";

import { useLocation } from "react-router-dom";
import { Container, Flex, Box, Divider, Button, Link } from "theme-ui";

import { InviteFormModal } from "src/components/modals/invite-modal";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Row } from "src/ui/box";
import { ChatMonoIcon, CalendarMonoIcon, SendMonoIcon, PlayIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

import posterImage from "./poster-image.jpg";

export const Footer: VFC = () => {
  const location = useLocation();
  const [openInvite, setOpenInvite] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  return (
    <>
      <Row sx={{ bg: "base.0", width: "100%", py: 12, borderTop: "1px solid", borderTopColor: "base.2" }}>
        <Container sx={{ maxWidth: "960px" }}>
          <Flex sx={{ justifyContent: "space-between", color: "base.5" }}>
            <Box sx={{ width: "100%", px: 8 }}>
              <Box sx={{ color: "base.6", fontWeight: "bold" }}>How Hightouch Works</Box>
              <Divider sx={{ borderColor: "base.3" }} />
              <Box sx={{ fontSize: "13px", lineHeight: "20px", mt: 4 }}>
                Watch a demo of Hightouch or learn more about our{" "}
                <Link
                  href={`${import.meta.env.VITE_DOCS_URL}/getting-started/concepts/`}
                  sx={{ display: "inline", color: "primaries.9" }}
                  target="_blank"
                  onClick={() => {
                    analytics.track("Documentation Opened", {
                      origin_page: location.pathname,
                    });
                  }}
                >
                  core concepts
                </Link>
                .
              </Box>
              <Button
                sx={{
                  ":hover:not(:disabled)": {
                    bg: "base.3",
                    ".playButton": {
                      bg: "primary",
                    },
                  },
                  width: "160px",
                  height: "90px",
                  bg: "base.3",
                  mt: 3,
                }}
                onClick={() => {
                  analytics.track("Video Opened", {
                    origin_page: location.pathname,
                  });
                  setOpenVideo(true);
                }}
              >
                <Box
                  className="playButton"
                  sx={{
                    bg: "secondaries.10",
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    p: "6px",
                  }}
                >
                  <PlayIcon color="white" />
                </Box>
              </Button>
              <Modal
                footer={<Button onClick={() => setOpenVideo(false)}>Close</Button>}
                header="Hightouch Demo"
                isOpen={openVideo}
                onClose={() => setOpenVideo(false)}
              >
                <video
                  controls
                  poster={posterImage}
                  preload="metadata"
                  src="https://www.master-7rqtwti-od3d6y3dmsppq.us-4.platformsh.site/uploads/Hightouch_Demo_V2_6030ab32ef_de67dbfd1c.mp4"
                  style={{ width: 700 }}
                />
              </Modal>
            </Box>
            <Box sx={{ width: "100%", px: 8 }}>
              <Box sx={{ color: "base.6", fontWeight: "bold" }}>Help With Setup</Box>
              <Divider sx={{ borderColor: "base.3" }} />
              <Box sx={{ fontSize: "13px", lineHeight: "20px", mt: 4 }}>
                Ask us about connecting a source or destination, setting up data models, or anything blocking your work in
                Hightouch.
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    newIntercomMessage("I want help with getting started.");
                    analytics.track("Help Requested", {
                      origin_page: location.pathname,
                    });
                  }}
                >
                  <ChatMonoIcon sx={{ mr: 2, opacity: "0.4" }} width={16} />
                  Chat
                </Button>
                <Box sx={{ fontSize: "13px", ml: 3, mr: 3 }}>or</Box>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    window.open("https://calendly.com/hightouch-experts/consultation", "_blank");
                    analytics.track("Book Session Opened", {
                      origin_page: location.pathname,
                    });
                    return false;
                  }}
                >
                  <CalendarMonoIcon sx={{ mr: 2, opacity: "0.4" }} width={16} />
                  Book Session
                </Button>
              </Box>
            </Box>
            <Box sx={{ width: "100%", px: 8 }}>
              <Box sx={{ color: "base.6", fontWeight: "bold" }}>Invite A Teammate</Box>
              <Divider sx={{ borderColor: "base.3" }} />
              <Box sx={{ fontSize: "13px", lineHeight: "20px", mt: 4 }}>
                Missing access credentials to a source? Don’t have an account in the destination you need?
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setOpenInvite(true);
                  }}
                >
                  <SendMonoIcon sx={{ mr: 2, opacity: "0.4" }} width={16} />
                  Send Invite
                </Button>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Row>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </>
  );
};
