import { useState, VFC } from "react";

import moment from "moment";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

export const LockOutModal: VFC = () => {
  const { workspace } = useUser();
  const [showModal, setShowModal] = useState<boolean>(true);

  if (!workspace || !workspace?.show_lock_popup || sessionStorage.getItem("lockoutDismissed")) {
    return null;
  }

  const isCompleteLockout = workspace.lock_popup_date
    ? moment(workspace.lock_popup_date, "YYYY-MM-DD").isBefore(moment())
    : false;

  analytics.track("Lock Out Modal Shown", {
    workspace_id: workspace.id,
    is_complete_lockout: isCompleteLockout,
    lock_popup_date: workspace.lock_popup_date,
  });

  const onClose = () => {
    if (!isCompleteLockout) {
      sessionStorage.setItem("lockoutDismissed", "true");
      analytics.track("Lock Out Modal Dismissed", {
        workspace_id: workspace.id,
        is_complete_lockout: isCompleteLockout,
        lock_popup_date: workspace.lock_popup_date,
      });
      setShowModal(false);
    }
  };

  const onLiveChatClick = () => {
    analytics.track("Lock Out Live Chat Clicked", {
      workspace_id: workspace.id,
      is_complete_lockout: isCompleteLockout,
      lock_popup_date: workspace.lock_popup_date,
    });

    newIntercomMessage(`Hi, I'd like to ${isCompleteLockout || workspace.lock_popup_date ? "unlock" : "upgrade"} my account!`);
  };

  return (
    <Modal
      footer={
        <>
          {isCompleteLockout ? null : (
            <Button variant="secondary" onClick={onClose}>
              Dismiss
            </Button>
          )}
          <Button onClick={onLiveChatClick}>Live Chat</Button>
        </>
      }
      isOpen={showModal}
      title="Account In Overage"
      onClose={onClose}
    >
      {isCompleteLockout ? (
        <Text>
          Your workspace is in overage and has been locked. Please reach out to your Hightouch rep to unlock your account.
        </Text>
      ) : workspace.lock_popup_date ? (
        <Text>
          Your workspace is in overage and will be locked in{" "}
          {moment(workspace.lock_popup_date, "YYYY-MM-DD").diff(moment(), "days") + 1} days. Please reach out to your Hightouch
          rep to upgrade your account.
        </Text>
      ) : (
        <Text>Your account is in overage. Please reach out to your Hightouch rep to upgrade your account.</Text>
      )}
    </Modal>
  );
};
