import { ReactElement } from "react";

import { ResourcePermissionGrant } from "src/graphql";
import { ResourcePermission } from "src/hooks/use-has-permission";
import { Audience } from "src/pages/audiences/audience";
import { Audiences } from "src/pages/audiences/audiences";
import { CloneAudience } from "src/pages/audiences/clone-audience";
import { CreateAudience } from "src/pages/audiences/create-audience";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { AudienceObjects } from "src/pages/audiences/setup/objects";
import { SyncTemplate } from "src/pages/audiences/setup/sync-template";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";
import { CreateDestination } from "src/pages/destinations/create-destination";
import { Destination } from "src/pages/destinations/destination";
import { Destinations } from "src/pages/destinations/destinations";
import { Alerting } from "src/pages/extensions/alerting";
import { DbtCloud } from "src/pages/extensions/dbt-cloud";
import { DbtModels } from "src/pages/extensions/dbt-models";
import { Extensions } from "src/pages/extensions/extensions";
import { GitSync as GitSyncExtension } from "src/pages/extensions/git-sync";
import { Looker } from "src/pages/extensions/looker";
import { Monitoring } from "src/pages/extensions/monitoring";
import { CloneModel } from "src/pages/models/clone-model";
import { CreateModel } from "src/pages/models/create-model";
import { Model } from "src/pages/models/model";
import { ModelQuery } from "src/pages/models/model-query";
import { Models } from "src/pages/models/models";
import { Onboarding } from "src/pages/onboarding/onboarding";
import { Pricing } from "src/pages/pricing";
import { APIKeys } from "src/pages/settings/api-keys";
import { Billing } from "src/pages/settings/billing";
import { CloudProviders } from "src/pages/settings/cloud-providers";
import { Members } from "src/pages/settings/members";
import { Roles } from "src/pages/settings/roles";
import { Storage } from "src/pages/settings/storage";
import { Tunnels } from "src/pages/settings/tunnels";
import { Usage } from "src/pages/settings/usage";
import { VersionControl } from "src/pages/settings/version-control";
import { Workspace } from "src/pages/settings/workspace";
import { CreateSource } from "src/pages/sources/create-source";
import { Source } from "src/pages/sources/source";
import { Sources } from "src/pages/sources/sources";
import { CreateSync } from "src/pages/syncs/create/create-sync";
import { RunDebug } from "src/pages/syncs/debug";
import { Run } from "src/pages/syncs/run";
import { Sync } from "src/pages/syncs/sync";
import { Syncs } from "src/pages/syncs/syncs";

export type RouteObject = {
  path: string;
  component: ReactElement;
  permissions?: ResourcePermission[];
  unauthorizedRedirect?: string;
  analyticsData: { pageName: string };
};

export const config: RouteObject[] = [
  { analyticsData: { pageName: "Onboarding Page" }, path: "onboarding", component: <Onboarding /> },
  { analyticsData: { pageName: "Pricing Page" }, path: "pricing", component: <Pricing /> },

  {
    analyticsData: { pageName: "Clone Model Page" },
    path: "models/:model_id/clone",
    component: <CloneModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { analyticsData: { pageName: "Model Query Page" }, path: "models/:model_id/query", component: <ModelQuery /> },
  {
    analyticsData: { pageName: "New Model Page" },
    path: "models/new",
    component: <CreateModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { analyticsData: { pageName: "Model Details Page" }, path: "models/:model_id", component: <Model /> },
  {
    analyticsData: { pageName: "Models Page" },
    path: "models",
    component: <Models />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/syncs",
  },
  {
    analyticsData: { pageName: "Sync Run Details Debugger Page" },
    path: "syncs/:sync_id/runs/:run_id/debug/:row_id",
    component: <RunDebug />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Debugger] }],
    unauthorizedRedirect: "/syncs/:sync_id",
  },
  {
    analyticsData: { pageName: "Sync Run Details Page" },
    path: "syncs/:sync_id/runs/:run_id",
    component: <Run />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Debugger] }],
    unauthorizedRedirect: "/syncs/:sync_id",
  },
  {
    analyticsData: { pageName: "New Sync Page" },
    path: "syncs/new",
    component: <CreateSync />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/syncs",
  },
  { analyticsData: { pageName: "Sync Details Page" }, path: "syncs/:sync_id", component: <Sync /> },
  { analyticsData: { pageName: "Syncs Page" }, path: "syncs", component: <Syncs /> },

  {
    analyticsData: { pageName: "New Source Page" },
    path: "sources/new",
    component: <CreateSource />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  { analyticsData: { pageName: "Source Details Page" }, path: "sources/:source_id", component: <Source /> },
  { analyticsData: { pageName: "Sources Page" }, path: "sources", component: <Sources /> },

  {
    analyticsData: { pageName: "New Destination Page" },
    path: "destinations/new",
    component: <CreateDestination />,
    permissions: [{ resource: "destination", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  {
    analyticsData: { pageName: "Destination Details Page" },
    path: "destinations/:destination_id",
    component: <Destination />,
  },
  { analyticsData: { pageName: "Destinations Page" }, path: "destinations", component: <Destinations /> },

  {
    analyticsData: { pageName: "[Extensions] Monitoring Page" },
    path: "extensions/monitoring/*",
    component: <Monitoring />,
  },
  {
    analyticsData: { pageName: "[Extensions] dbt Cloud Page" },
    path: "extensions/dbt-cloud/*",
    component: <DbtCloud />,
  },
  {
    analyticsData: { pageName: "[Extensions] dbt Models Page" },
    path: "extensions/dbt-models/*",
    component: <DbtModels />,
  },
  {
    analyticsData: { pageName: "[Extensions] Looker Page" },
    path: "extensions/looker/*",
    component: <Looker />,
  },
  {
    analyticsData: { pageName: "[Extensions] Alerting Page" },
    path: "extensions/alerting/*",
    component: <Alerting />,
  },
  {
    analyticsData: { pageName: "[Extensions] Git Sync Page" },
    path: "extensions/git-sync/*",
    component: <GitSyncExtension />,
  },
  {
    analyticsData: { pageName: "Extensions Page" },
    path: "extensions",
    component: <Extensions />,
  },

  {
    analyticsData: { pageName: "[Settings] Storage Page" },
    path: "settings/storage",
    component: <Storage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Tunnels Page" },
    path: "settings/tunnels",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Tunnel Details Page" },
    path: "settings/tunnels/:modal_type",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Version Control Page" },
    path: "settings/version-control",
    component: <VersionControl />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] API Keys Page" },
    path: "settings/api-keys",
    component: <APIKeys />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Cloud Providers Page" },
    path: "settings/cloud-providers",
    component: <CloudProviders />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Usage Page" },
    path: "settings/usage",
    component: <Usage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Billing Page" },
    path: "settings/billing",
    component: <Billing />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Roles Page" },
    path: "settings/roles",
    component: <Roles />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  { analyticsData: { pageName: "[Settings] Members Page" }, path: "settings/members", component: <Members /> },
  {
    analyticsData: { pageName: "Settings Page" },
    path: "settings/*",
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
    component: <Workspace />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] New Sync Template Page" },
    path: "audiences/setup/sync-templates/new",
    component: <CreateSyncTemplate />,
    permissions: [{ resource: "sync_template", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/setup",
  },
  {
    analyticsData: { pageName: "[Audience Setup] Sync Template Details Page" },
    path: "audiences/setup/sync-templates/:sync_template_id",
    component: <SyncTemplate />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Sync Templates Page" },
    path: "audiences/setup/sync-templates/",
    component: <SyncTemplates />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Audience Details Query Page" },
    path: "audiences/setup/:object/:audience_id/query",
    component: <AudienceObjectQuery />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] New Object Page" },
    path: "audiences/setup/:object/new",
    component: <CreateAudienceObject />,
    permissions: [{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/setup",
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Audience Detail Page" },
    path: "audiences/setup/:object/:audience_id",
    component: <AudienceObject />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Objects Page" },
    path: "audiences/setup/:object",
    component: <AudienceObjects />,
  },
  {
    analyticsData: { pageName: "Clone Audience Page" },
    path: "audiences/:audience_id/clone",
    component: <CloneAudience />,
    permissions: [{ resource: "audience", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/setup",
  },
  {
    analyticsData: { pageName: "New Audience Page" },
    path: "audiences/new",
    component: <CreateAudience />,
    permissions: [{ resource: "audience", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences",
  },
  {
    analyticsData: { pageName: "Audience Details Page" },
    path: "audiences/:audience_id",
    component: <Audience />,
  },
  {
    analyticsData: { pageName: "Audiences Page" },
    path: "audiences",
    component: <Audiences />,
  },
];
