import { FC, useState, VFC } from "react";

import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { useUser } from "src/contexts/user-context";
import Approval from "src/pages/approval";
import { Expired } from "src/pages/expired";
import Hello from "src/pages/hello";
import { Invalid } from "src/pages/invalid";
import Login from "src/pages/login";
import PartnerConnect from "src/pages/partner-connect";
import RedirectPage from "src/pages/redirect";
import Signup from "src/pages/signup";
import SSO from "src/pages/sso";
import { Unauthorized } from "src/pages/unauthorized";
import WorkspaceNotFoundPage from "src/pages/workspace-not-found";
import { NewWorkspace } from "src/pages/workspaces/new";
import { Workspaces } from "src/pages/workspaces/workspaces";
import { query } from "src/utils/query";
import { switchWorkspace } from "src/utils/workspaces";

import { AuthLayout } from "../auth/auth-layout";
import { Passwordless } from "../auth/passwordless";

const topLevelRoutes = ["syncs", "models", "destinations", "sources", "settings", "audiences", "extensions"];

const ProtectedRoute: FC = ({ children }) => {
  const { user } = useUser();
  const location = useLocation();

  if (user) {
    return <>{children}</>;
  }

  return <Navigate state={location?.state} to={`/login?returnTo=${encodeURI(location?.pathname)}`} />;
};

const UserRedirect: FC<{ validPathname?: boolean }> = ({ children, validPathname }) => {
  const { user, slug: workspaceSlug, onboarding, getWorkspaceIdFromSlug } = useUser();
  const location = useLocation();
  const { slug: routeSlug } = useParams();
  const [workspaceNotFound, setWorkspaceNotFound] = useState(false);

  const defaultPath = onboarding ? "/onboarding" : "/syncs";

  if (routeSlug && routeSlug !== workspaceSlug && !topLevelRoutes.includes(routeSlug)) {
    const workspaceId = getWorkspaceIdFromSlug(routeSlug);
    let promise: Promise<boolean> | undefined;
    if (workspaceId) {
      promise = switchWorkspace(workspaceId, location.pathname);
    } else if (user?.is_admin) {
      promise = switchWorkspaceBySlug(routeSlug, location.pathname);
    }
    if (workspaceNotFound || !promise) {
      return <WorkspaceNotFoundPage />;
    }
    promise.then((ret) => {
      setWorkspaceNotFound(!ret);
    });
    return null;
  } else if (workspaceSlug) {
    if (!location.pathname.startsWith(`/${encodeURIComponent(workspaceSlug)}`)) {
      if (validPathname) {
        return (
          <Navigate
            replace
            to={`/${workspaceSlug}${location.pathname === "/" ? defaultPath : location.pathname}${location.search}`}
          />
        );
      } else {
        return <Navigate replace to={`/${workspaceSlug}${defaultPath}${location.search}`} />;
      }
    }
    return null;
  } else if (user) {
    return <Navigate to="/workspaces" />;
  } else if (location.pathname === "/") {
    return <Navigate to="/login" />;
  } else {
    return <>{children}</>;
  }
};

// only for routes that are not prefixed with a workspace slug
export const SluglessRouter: VFC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          element={
            <UserRedirect>
              <Passwordless />
            </UserRedirect>
          }
          path="/signup/email"
        />
        <Route
          element={
            <UserRedirect>
              <Signup />
            </UserRedirect>
          }
          path="/signup"
        />
        <Route
          element={
            <UserRedirect>
              <Passwordless />
            </UserRedirect>
          }
          path="/login/email"
        />
        <Route
          element={
            <UserRedirect>
              <Login />
            </UserRedirect>
          }
          path="/login"
        />

        <Route
          element={
            <UserRedirect>
              <SSO />
            </UserRedirect>
          }
          path="/sso"
        />
        <Route
          element={
            <UserRedirect>
              <SSO />
            </UserRedirect>
          }
          path="/sso/:org"
        />
        <Route
          element={
            <ProtectedRoute>
              <PartnerConnect />
            </ProtectedRoute>
          }
          path="/partner-connect/:uuid"
        />
        <Route
          element={
            <ProtectedRoute>
              <NewWorkspace />
            </ProtectedRoute>
          }
          path="/workspaces/new"
        />
        <Route
          element={
            <ProtectedRoute>
              <Workspaces />
            </ProtectedRoute>
          }
          path="/workspaces"
        />
        <Route element={<Unauthorized />} path="/unauthorized" />
      </Route>

      <Route element={<Approval />} path="/approval" />
      <Route element={<Expired />} path="/expired" />
      <Route element={<Invalid />} path="/invalid" />
      <Route element={<RedirectPage />} path="/redirect" />

      <Route
        element={
          <ProtectedRoute>
            <Hello />
          </ProtectedRoute>
        }
        path="/hello"
      />
      <Route
        element={
          <ProtectedRoute>
            <UserRedirect validPathname>
              <Login />
            </UserRedirect>
          </ProtectedRoute>
        }
        path="/:slug/*"
      />
      <Route
        element={
          <UserRedirect validPathname>
            <Login />
          </UserRedirect>
        }
        path="/"
      />
    </Routes>
  );
};

const switchWorkspaceBySlug = async (slug: string, path?: string) => {
  const data = await query<{ workspaces?: { id: string }[] }>(
    `query WorkspaceBySlug($slug: String!) { workspaces(where: { slug: { _ilike: $slug } }) { id } }`,
    {
      slug,
    },
    {
      isAdmin: true,
    },
  );

  const id = data.workspaces?.[0]?.id;

  if (id) {
    return switchWorkspace(id, path);
  }
  return false;
};
