import { useLayoutEffect, FC, ReactNode } from "react";

import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import insightsClient from "search-insights";

import { useUser } from "src/contexts/user-context";

export const InsightsMiddleware: FC<{ children: ReactNode }> = ({ children }) => {
  const { use } = useInstantSearch();
  const { user } = useUser();

  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient,
      onEvent: (event, client) => {
        const { insightsMethod, payload, eventType } = event;

        if (insightsMethod && client) {
          if (eventType === "click") {
            client(insightsMethod, payload);
          }
        }
      },
    });

    if (user?.id) {
      insightsClient("setUserToken", String(user.id));
    }

    return use(middleware);
  }, [use]);

  return <>{children}</>;
};
