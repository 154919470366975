import { VFC } from "react";

import { Image, Box, Heading, Text } from "theme-ui";

import { SyncQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Button } from "src/ui/button";
import { Confetti } from "src/ui/confetti";
import { ArrowRightIcon, CheckCircleIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";

import { colors } from "../../../../design/colors";
import { Subtitle } from "./subtitle";

type ConfettiModalProps = {
  isOpen: boolean;
  sourceIcon: string | undefined;
  sourceTitle: string | undefined;
  destinationIcon: string | undefined;
  destinationTitle: string | undefined;
  sync: SyncQuery["syncs"][0];
};

export const ConfettiModal: VFC<ConfettiModalProps> = ({
  isOpen,
  sourceTitle,
  sourceIcon,
  destinationTitle,
  destinationIcon,
  sync,
}) => {
  return (
    <Modal
      bodySx={{ bg: "transparent", pb: 4 }}
      footer={
        <>
          <Link to={`/syncs/${sync.id}?autorun=true`}>
            <Button
              propagate
              size="large"
              sx={{ width: "100%", mb: 2 }}
              onClick={() => {
                analytics.track("Onboarding Run Sync Button Clicked", {
                  sync_id: sync.id,
                });
              }}
            >
              Run this sync now
            </Button>
          </Link>
          <Link to="/syncs">
            <Button
              propagate
              size="large"
              sx={{ width: "100%" }}
              variant="secondary"
              onClick={() => {
                analytics.track("Go To Syncs Clicked", {});
              }}
            >
              Done
            </Button>
          </Link>
        </>
      }
      footerSx={{ border: "none", display: "block" }}
      header={
        <Box sx={{ width: "100%", mt: 6, textAlign: "center" }}>
          <Heading sx={{ fontWeight: 700, fontSize: "32px" }} variant="h2">
            Setup is complete
          </Heading>
        </Box>
      }
      headerSx={{ border: "none" }}
      isOpen={isOpen}
      title="Setup is complete"
      onClose={() => {}}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 6 }}>
          <CheckCircleIcon color={colors.green} />
          <Text ml={2}>Your first sync has been created</Text>
        </Box>
        <Confetti showConfetti={true}>
          <Box sx={{ display: "flex", alignItems: "center", minWidth: "678px", mb: 8 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: `2px solid ${colors.base[3]}`,
                p: 4,
                width: "100%",
              }}
            >
              <Image alt={sourceTitle} src={sourceIcon} width={36} />
              <Box ml={4}>
                <Subtitle text="Source" />
                <Text sx={{ fontWeight: 600, fontSize: "14px", flexGrow: 1 }}>{sourceTitle}</Text>
              </Box>
            </Box>
            <ArrowRightIcon color={colors.base[3]} sx={{ mx: 4 }} width={24} />
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: `2px solid ${colors.base[3]}`,
                p: 4,
                width: "100%",
              }}
            >
              <Image alt={destinationTitle} src={destinationIcon} width={36} />
              <Box ml={4}>
                <Subtitle text="Destination" />
                <Text sx={{ fontWeight: 600, fontSize: "14px", flexGrow: 1 }}>{destinationTitle}</Text>
              </Box>
            </Box>
          </Box>
        </Confetti>
        <Box>
          <Text mb={1}>Before running your sync you can:</Text>
          <Box sx={{ lineHeight: 2, ml: 1 }}>
            <ul>
              <li>Refine and edit the data model</li>
              <li>Edit the sync configuration</li>
              <li>Invite teammates to this workspace to ensure setup is correct</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
