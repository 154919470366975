import { FormkitBoolean } from "./booleans";
import { Option } from "./components/option";

export type FormkitReference =
  | FormkitStateReference
  | FormkitContextReference
  | FormkitGraphQLReference<GraphQLVariables>;

export enum ReferenceType {
  State = "state",
  Context = "context",
  GraphQL = "graphQL",
}

export type BaseReference = {
  type: ReferenceType;
};

export type FormkitStateReference = BaseReference & {
  type: ReferenceType.State;
  key: string;
};

export type FormkitContextReference = BaseReference & {
  type: ReferenceType.Context;
  key: string;
};

export type FormkitGraphQLReference<
  TVariables extends GraphQLVariables = GraphQLVariables,
> = BaseReference & {
  type: ReferenceType.GraphQL;
  document: string;
  variables?: TVariables;
};

export type GraphQLVariables = {
  [key: string]: GraphQLVariablesValue;
};

export type GraphQLVariablesValue =
  | GraphQLVariables
  | FormkitReference
  | FormkitBoolean
  | boolean
  | string
  | number
  | undefined;

export type HandlerGraphQLVariables = {
  input: {
    handler: string;
    variables?: GraphQLVariables;
    context: GraphQLVariables;
  };
};

export type FormkitHandler<
  Variables,
  ContextType extends FormkitHandlerContext | FormkitSourceHandlerContext,
  Return,
> = (
  vars: Variables,
  queryCtx: ContextType,
  ctx?: Record<string, any>,
) => Promise<Return>;

//Default context is sync context, but future PR should remove default.
export type FormkitOptionsHandler<
  Variables,
  ContextType extends
    | FormkitHandlerContext
    | FormkitSourceHandlerContext = FormkitHandlerContext,
> = FormkitHandler<Variables, ContextType, Option[]>;

/**
 * Context for sync configuration
 * Should be renamed in a seperate PR.
 */
export type FormkitHandlerContext = {
  destination: { id: number; type: string; config: any };
  model: {
    id: number;
    name: string;
    columns: { name: string; type: string }[];
  };
  sync?: { id: number; config: any };
};

export type FormkitSourceHandlerContext = {
  /**Type of source. Example: 'athena' */
  type: string;
  workspaceId: string;
  /** Source definition */
  definition: Record<string, any>;
};
