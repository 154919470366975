import { Buffer } from "buffer";

import { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";

import { Header, SidebarForm } from "src/components/page";
import { Slug } from "src/components/slug";
import { SourceForm } from "src/components/sources/forms/form";
import { SourceCatalog } from "src/components/sources/source-catalog";
import { TestNewSourceButton, TestResult, TestSourceBadge, TestUpdatedSourceButton } from "src/components/sources/test-source";
import { DocsLink, PlannerDatabase } from "src/components/sources/warehouse-planning";
import { useUser } from "src/contexts/user-context";
import {
  SourceDefinition,
  useIsResourceSlugAvailableQuery,
  useSampleDataSourceDefinitionsQuery,
  useSetupWarehousePlanningWithCredentialsMutation,
  useSourceDefinitionsQuery,
  useCreateSourceV2Mutation,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Message } from "src/ui/message";
import { generateSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

export type NewSource = { id?: string; definition: SourceDefinition | undefined };

type CreateSourceProps = {
  initialStep?: number;
  previousSourceDefinition?: SourceDefinition;
  onSubmit?: (args: NewSource) => void;
  onConnectClick?(defintion: SourceDefinition): void;
};

export const useCreateSourceWizard = ({
  initialStep = 0,
  previousSourceDefinition,
  onSubmit,
  onConnectClick,
}: Readonly<CreateSourceProps>) => {
  const { addToast } = useToasts();
  const {
    data: { source: sourceFromQueryString },
  } = useQueryString();
  const { user } = useUser();
  const [name, _setName] = useState<string>("");
  const [slug, _setSlug] = useState<string>("");
  const [dirtySlug, setDirtySlug] = useState<boolean>(false);
  //Selected source (from Step 1) or matching source from ID
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [config, setConfig] = useState<any>();
  const [tunnel, setTunnel] = useState<any>();
  const [credentialId, setCredentialId] = useState<string>();
  const [enabledWarehousePlanning, setEnabledWarehousePlanning] = useState<boolean>(false);
  const [plannerDatabase, setPlannerDatabase] = useState<string>();
  const [enabledError, setEnabledError] = useState<Error | null>();

  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);
  const [step, setStep] = useWizardStepper(initialStep);

  const {
    data: { id: oAuthId, onboardingSourceId }, //IDs would exist for sources that are authenticated by oauth, yet can still be incomplete.
  } = useQueryString();

  const id = oAuthId ?? onboardingSourceId;

  // Retrieve the source if already exists (Steps 2 and 3)
  const { data: existingOauthSource } = useSource(id ?? "", {
    pause: !id,
  });
  const matchingSourceConfig = existingOauthSource?.config;
  const matchingSourceDefinition = existingOauthSource?.definition;

  const { mutateAsync: createSource, isLoading: creating } = useCreateSourceV2Mutation();
  const { mutateAsync: updateSource, isLoading: updating } = useUpdateSourceV2Mutation();
  const {
    mutateAsync: setupWarehousePlanning,
    isLoading: setupWarehousePlanningLoading,
    error: setupWarehousePlanningError,
  } = useSetupWarehousePlanningWithCredentialsMutation();

  const { data: sourceDefinitionsData, isLoading: loadingCatalog } = useSourceDefinitionsQuery();
  const sourceDefinitions = sourceDefinitionsData?.getSourceDefinitions;

  const { data: sampleDataSourcesData, isLoading: loadingSampleDataSources } = useSampleDataSourceDefinitionsQuery();
  const sampleDataSources = sampleDataSourcesData?.getSampleDataSourceDefinitions;

  const { data, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "connections",
      slug,
    },
    { enabled: Boolean(slug) },
  );

  const available = data?.isResourceSlugAvailable;

  const setSlug = (value) => {
    _setSlug(value);
    setDirtySlug(true);
  };

  const setName = (value) => {
    _setName(value);
    if (!dirtySlug) {
      _setSlug(generateSlug(value));
    }
  };

  //Set source, if found by possibly existing ID.
  useEffect(() => {
    if (matchingSourceConfig && matchingSourceDefinition) {
      setConfig(matchingSourceConfig);
      setSourceDefinition(matchingSourceDefinition);
      setStep(1);
    }
  }, [id, Boolean(matchingSourceConfig), Boolean(matchingSourceDefinition)]);

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Source Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult]);

  useEffect(() => {
    if (step === 2) {
      analytics.track("Source Slug Screen Viewed", {
        source_type: sourceDefinition?.type ?? "",
      });
    }
  }, [step]);

  useEffect(() => {
    if (sourceFromQueryString && !sourceDefinition) {
      const decodeSource = JSON.parse(Buffer.from(sourceFromQueryString, "base64").toString());
      if (decodeSource.type === "googlesheets") {
        setSourceDefinition(sourceDefinitions?.find((def) => def.type === "googlesheets"));
      }
      setStep(1);
    }
  }, [sourceDefinitions, sourceFromQueryString]);

  // move to second step if source definition is provided
  useEffect(() => {
    if (previousSourceDefinition) {
      setSourceDefinition(previousSourceDefinition);
      if (previousSourceDefinition.isSampleDataSource) {
        setConfig({});
        setStep(2);
      } else {
        setStep(1);
      }
    }
  }, [previousSourceDefinition]);

  const create = async () => {
    let createdId: string | undefined;
    if (id) {
      const { updateSourceWithSecrets } = await updateSource({
        id: String(id),
        object: {
          config,
          name,
          slug,
          setup_complete: true,
        },
      });
      createdId = updateSourceWithSecrets?.id.toString();
    } else {
      const { createSourceWithSecrets } = await createSource({
        object: {
          name,
          slug,
          config,
          type: sourceDefinition?.isSampleDataSource ? "sample-data" : sourceDefinition?.type,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
          tunnel_id: tunnel?.id,
          credential_id: credentialId ? String(credentialId) : undefined,
          plan_in_warehouse: enabledWarehousePlanning,
          sample_data_source_id: sourceDefinition?.isSampleDataSource ? sourceDefinition.type : null,
          plan_in_warehouse_config: {
            plannerDatabase,
          },
        },
      });
      createdId = createSourceWithSecrets?.id.toString();
    }

    addToast(`Source ${name} created!`, {
      appearance: "success",
    });

    analytics.track("Source Created", {
      source_name: name,
      source_type: sourceDefinition?.type ?? "",
    });

    onSubmit?.({ id: createdId, definition: sourceDefinition });
  };

  const steps = [
    {
      pageSize: "medium",
      title: "Select",
      disabled: !sourceDefinition,
      onContinue: () => {
        if (sourceDefinition?.isSampleDataSource) {
          setConfig({});
          setStep(2);
        } else {
          setStep(1);
        }
      },
      render: () => (
        <>
          <Row
            sx={{
              alignItems: "center",
              pb: 6,
              mb: 8,
              borderBottom: "small",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Heading variant="h1">Source catalog</Heading>
            <Button
              variant="secondary"
              onClick={() => {
                newIntercomMessage("Hi, I would like to request a new data source. I want to sync data from ____.");
                analytics.track("Request a source clicked");
              }}
            >
              Request a source
            </Button>
          </Row>
          <Grid gap={4}>
            {sampleDataSources && sourceDefinitions && (
              <SourceCatalog
                sampleDataSources={sampleDataSources}
                selection={sourceDefinition}
                sourceDefinitions={sourceDefinitions}
                onSelect={setSourceDefinition}
              />
            )}
          </Grid>
        </>
      ),
    },
    {
      pageSize: "medium",
      title: `Connect`,
      disabled: sourceDefinition?.isSampleDataSource,
      onContinue: () => {
        // empty function needed to set step after submit is triggered
      },
      continueProps: { form: "source-form", type: "submit" },
      render: () => {
        if (!sourceDefinition) {
          return null;
        }

        return (
          <>
            <Header
              icon={sourceDefinition.icon}
              rightToolbar={[<TestSourceBadge key={0} result={testResult} testing={testing} />]}
              title={`Connect ${sourceDefinition.name}`}
            />

            <Row sx={{ alignItems: "flex-start" }}>
              <Grid gap={8} mr={8} sx={{ flexGrow: 1 }}>
                <SourceForm
                  config={config}
                  credentialId={credentialId}
                  definition={sourceDefinition}
                  disableAuthMethod={Boolean(id)}
                  error={testError}
                  isSetup={true}
                  setConfig={setConfig}
                  setCredentialId={setCredentialId}
                  setTunnel={setTunnel}
                  sourceId={id}
                  tunnel={tunnel}
                  onConnectClick={onConnectClick}
                  onSubmit={() => {
                    setStep(step + 1);
                    return Promise.resolve();
                  }}
                />
              </Grid>
              <SidebarForm
                buttons={
                  !id ? (
                    <TestNewSourceButton
                      configuration={config}
                      credentialId={credentialId}
                      definition={sourceDefinition}
                      tunnelId={tunnel?.id}
                      onError={setTestError}
                      onResult={setTestResult}
                    />
                  ) : (
                    <TestUpdatedSourceButton
                      buttonProps={{ width: "100%" }}
                      credentialId={credentialId}
                      newConfiguration={config}
                      sourceId={id}
                      tunnelId={tunnel?.id}
                      onError={setTestError}
                      onLoading={setTesting}
                      onResult={setTestResult}
                    />
                  )
                }
                docsUrl={sourceDefinition?.docs ?? ""}
                name={sourceDefinition?.name ?? ""}
              />
            </Row>
          </>
        );
      },
    },
    {
      pageSize: "small",
      title: "Finish",
      loading: creating || updating,
      disabled: !name || !available || !slug,
      render: () => (
        <>
          <Header icon={sourceDefinition?.icon} title={`Connect ${sourceDefinition?.name}`} />
          <Row sx={{ alignItems: "flex-start" }}>
            <Grid gap={12} mr={8} sx={{ flexGrow: 1 }}>
              <Field label="Name">
                <Input value={name} onChange={(value) => setName(value)} />
              </Field>
              <Field label="Slug">
                <Slug
                  available={Boolean(available)}
                  loading={loadingSlug}
                  placeholder={"your-source-slug"}
                  value={slug}
                  onChange={setSlug}
                />
              </Field>
              {sourceDefinition?.supportsInWarehouseDiffing && (
                <Column>
                  <Heading variant="h3">Would you like to enable warehouse planning?</Heading>
                  <Text sx={{ my: 4, color: "base.7" }}>
                    Warehouse-level planning improves sync speed by doing more computation in your data warehouse.
                  </Text>
                  {setupWarehousePlanningError ? (
                    <Column>
                      <FieldError error={enabledError} sx={{ mb: 2 }} />
                      <Message variant="error">
                        Failed to enable. Your account might not have write permissions. See our <DocsLink>docs</DocsLink> for
                        instructions.
                      </Message>
                    </Column>
                  ) : (
                    <Message>
                      Warehouse planning requires write permissions, see our <DocsLink>docs</DocsLink> for detailed instructions
                      on obtaining these.
                    </Message>
                  )}
                  {sourceDefinition.supportsCrossDbReference && (
                    <PlannerDatabase plannerDatabase={plannerDatabase} onChange={setPlannerDatabase} />
                  )}
                  <Button
                    disabled={enabledWarehousePlanning}
                    loading={setupWarehousePlanningLoading}
                    sx={{ mt: 4, width: "70px" }}
                    variant="dark"
                    onClick={() => {
                      setupWarehousePlanning(
                        {
                          type: sourceDefinition?.type,
                          config: JSON.stringify(config),
                          plannerDatabase: plannerDatabase,

                          // @ts-expect-error credentialId should be string in GQL
                          credentialId: credentialId as number,

                          tunnelId: tunnel?.id,
                        },

                        {
                          onSuccess: () => {
                            setEnabledWarehousePlanning(true);
                          },
                          onError: (error) => {
                            setEnabledError(error);
                          },
                        },
                      );
                    }}
                  >
                    {enabledWarehousePlanning ? "Enabled" : "Enable"}
                  </Button>
                </Column>
              )}
            </Grid>
          </Row>
        </>
      ),
    },
  ];

  return {
    createSource: create,
    loading: loadingCatalog || loadingSampleDataSources,
    sourceDefinition,
    step,
    steps,
    setStep,
    id: id,
  };
};
