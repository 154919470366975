import { VFC, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import store from "store2";
import { Text, ThemeUIStyleObject, Message, Flex } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ShieldIcon, XIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

import { colors } from "../../../../design/colors";

type Props = {
  sx?: ThemeUIStyleObject;
};

const permissionsMessageStorageKey = "permissions-message-dismissed";

export const PermissionsMessage: VFC<Readonly<Props>> = ({ sx = {} }) => {
  const { appEnableAdvancedPermissionsBanner } = useFlags();

  const { user, workspace } = useUser();
  const [displayPermissionsMessage, setDisplayPermissionsMessage] = useState(() => {
    const dismissed = Boolean(store.get(permissionsMessageStorageKey));

    return !dismissed;
  });

  const contact = () => {
    const intercom = window["Intercom"];

    if (typeof intercom === "function") {
      intercom("showNewMessage", "Hi, I'm interested in trying out advanced access management features.");
    }

    analytics.track("Permissions Message CTA Clicked", {
      user_id: user?.id,
      workspace_id: workspace?.id,
    });
  };

  const dismiss = () => {
    setDisplayPermissionsMessage(false);
    store.set(permissionsMessageStorageKey, true);
  };

  if (!appEnableAdvancedPermissionsBanner || !displayPermissionsMessage) {
    return null;
  }

  return (
    <Message
      sx={{
        width: "unset",
        maxWidth: "unset",
        bg: "base.1",
        borderRadius: 1,
        p: 4,
        border: `1px solid ${colors.base[2]}`,
        mb: 8,
        ...sx,
      }}
    >
      <Flex sx={{ alignItems: "center", justifyContent: "space-between", gap: 3 }}>
        <Row sx={{ alignItems: "center" }}>
          <ShieldIcon color="base.6" />
          <Text color="base.6" ml={2} sx={{ fontSize: "13px" }}>
            For more granular permissions,{"  "}
            <Link onClick={contact}>click here</Link>
            {"  "}
            to chat with us about our advanced Access Management features.
          </Text>
        </Row>
        <Button
          aria-label="Dismiss Message"
          iconBefore={<XIcon size={16} />}
          sx={{
            alignSelf: "flex-start",
            p: 0,
            height: "20px",
            width: "20px",
            border: "none",
            color: "purple",
            ":hover > div": {
              backgroundColor: "base.2",
              borderRadius: "6px",
              height: "20px",
              width: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            svg: {
              fill: "base.5",
            },
          }}
          variant="secondary"
          onClick={dismiss}
        />
      </Flex>
    </Message>
  );
};
