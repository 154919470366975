import { useEffect, VFC } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon } from "src/ui/icons";
import { Select } from "src/ui/select";

import { useFormkitContext } from "./formkit-context";
import { formatOptionLabel, formatFromColumnOption } from "./mappings";
import { MappingsHeader } from "./mappings-header";

type Props = {
  name: string;
  options?: any;
  loading?: any;
  object?: string;
  error?: string;
  reload?: () => void;
};

const retrieveErrorMessage = (errors, name: string) => {
  for (const direction of ["from", "to"]) {
    const mappingPath = `${name}.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

export const Mapping: VFC<Readonly<Props>> = ({ name, object, options, loading, reload, error }) => {
  const { columns } = useFormkitContext();
  const { errors } = useDestinationForm();
  const { watch, setValue } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (!value) {
      setValue(name, {});
    }
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Column>
      <Grid sx={{ gridTemplateColumns: "1fr max-content 1fr max-content", alignItems: "center" }}>
        <MappingsHeader columns={4} loading={loading} object={object} reload={reload} />

        <Controller
          name={name}
          render={(data) => {
            return (
              <>
                <Select
                  formatOptionLabel={formatFromColumnOption}
                  isError={!!errors?.[`${data.field.name}.from`]}
                  options={columns}
                  placeholder="Select a column..."
                  value={data.field.value.from}
                  onChange={(option) => data.field.onChange({ ...data.field.value, from: option?.value || undefined })}
                />

                <ArrowRightIcon color="base.3" size={16} />

                <Select
                  formatOptionLabel={formatOptionLabel}
                  isError={!!errors?.[`${data.field.name}.to`]}
                  options={options}
                  placeholder="Select a field..."
                  value={data.field.value.to}
                  onChange={(option) => data.field.onChange({ ...data.field.value, to: option?.value || undefined })}
                />
              </>
            );
          }}
        />
      </Grid>

      <FieldError error={retrieveErrorMessage(errors, name) || error} />
    </Column>
  );
};
