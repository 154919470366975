import { FC, ReactNode, useEffect, useRef } from "react";

import { WorkspaceValidation } from "src/components/layout/header/workspace-validation";
import { useHeaderHeight } from "src/contexts/header-height-context";

import { Indices } from "../../../../../design";
import { Breadcrumbs, Crumb } from "./breadcrumbs";
import { Header, Topbar } from "./header";
import { OverageBanner } from "./overage-banner";
import { TrialBanner } from "./trial-banner";
import { UserMenu } from "./user-menu";
import { WorkspaceWarning } from "./workspace-warning";

export type Props = {
  crumbs?: Crumb[];
  outsideTopbar?: ReactNode;
};

export const PageHeader: FC<Readonly<Props>> = ({ children, crumbs, outsideTopbar }) => {
  const { setHeaderHeight } = useHeaderHeight();
  const headerElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const headerObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setHeaderHeight(entry.contentRect.height);
      });
    });

    if (headerElement.current) {
      headerObserver.observe(headerElement.current);
    }

    return () => {
      headerObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={headerElement}
      style={{
        position: "sticky",
        width: "100%",
        top: 0,
        left: 0,
        height: "max-content",
        zIndex: Indices.Popup,
      }}
    >
      <Header>
        <WorkspaceWarning />
        <WorkspaceValidation />
        <TrialBanner />
        <OverageBanner />
        <Topbar>
          {children || (
            <>
              {crumbs && <Breadcrumbs crumbs={crumbs} />}
              <UserMenu />
            </>
          )}
        </Topbar>
        {outsideTopbar}
      </Header>
    </div>
  );
};
