import { VFC } from "react";

import { Text } from "theme-ui";

import { ResourcePermissionGrant, ResourceToPermission } from "src/graphql";
import { Row } from "src/ui/box";
import { DotsIcon } from "src/ui/icons";
import { Menu } from "src/ui/menu";
import { NewSelect } from "src/ui/new-select";

import { Permission } from "../permission";
import { Filter } from "./types";

type View = {
  id: string;
  name: string;
  view: Filter[];
};

type ViewsProps = {
  deletePermissionResource: ResourceToPermission;
  value: string;
  onChange: (view: { view: string; filters: Filter[] }) => void;
  onDelete: ({ id }: { id: string }) => void;
};

type Props = ViewsProps & {
  views: View[];
  loading?: boolean;
};

export const Views: VFC<Readonly<Props>> = ({ deletePermissionResource, value, views = [], onChange, onDelete }) => {
  const handleChange = (newValue: string) => {
    if (newValue === "Default view") {
      onChange({ view: newValue, filters: [] });
    } else {
      const selectedView = views.find((view) => view.name === newValue);

      if (selectedView) {
        onChange({ view: newValue, filters: selectedView.view });
      }
    }
  };

  const options = [
    { label: "Default view", value: "Default view" },
    ...views.map(({ id, name }) => ({
      label: String(name),
      value: String(name),
      render: () => (
        <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <Text sx={{ mr: 2 }}>{name}</Text>
          <Permission permissions={[{ resource: deletePermissionResource, grants: [ResourcePermissionGrant.Delete] }]}>
            <Menu
              options={[
                {
                  label: "Delete",
                  onClick: () => {
                    onDelete({ id });
                    if (name === value) {
                      onChange({ view: "Default view", filters: [] });
                    }
                  },
                  variant: "danger",
                },
              ]}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <DotsIcon color={name === value ? "primary" : "base.4"} size={16} />
            </Menu>
          </Permission>
        </Row>
      ),
    })),
  ];

  return <NewSelect options={options} value={value as any} onChange={handleChange} />;
};
