import { useEffect, useMemo, useState, VFC } from "react";

import { useToasts } from "react-toast-notifications";
import { Grid, Image, Text } from "theme-ui";

import { RefreshIntervals } from "src/components/audiences/column-settings";
import { useObject } from "src/components/audiences/use-object";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { Slug } from "src/components/slug";
import { SourcesGrid } from "src/components/sources/sources-grid";
import { useUser } from "src/contexts/user-context";
import {
  useCreateEventMutation,
  useCreateObjectMutation,
  useUpdateModelColumnsMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { useSources, UseSourcesResult } from "src/utils/sources";

import labelExampleImage from "./label-example.png";
import labelsImage from "./labels.png";

type Source = UseSourcesResult["data"][0];

export const CreateAudienceObject: VFC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { objectPath, objectNameSingular, isParentModel, isEvent } = useObject();

  const [source, setSource] = useState<Source | undefined>();
  const [step, setStep] = useState<number>(0);
  const { queryState, resetQueryState, setSQL, setTable, setDBTModel } = useQueryState();
  const { modelState, setName, setPrimaryKey, setTimestampColumn, setSlug } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [primaryLabel, setPrimaryLabel] = useState<string>();
  const [secondaryLabel, setSecondaryLabel] = useState<string>();
  const [creating, setCreating] = useState<boolean>(false);

  const { data: sources, loading: sourcesLoading, error: sourcesError } = useSources();

  const supportedSources = useMemo(
    () => sources?.filter((source) => source.definition?.supportedQueries.includes(QueryType.Visual)),
    [sources],
  );

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: createEvent } = useCreateEventMutation();
  const { mutateAsync: updateModelColumns } = useUpdateModelColumnsMutation();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const create = async () => {
    setCreating(true);

    const data = await createObject({
      input: {
        is_schema: true,
        query_type: type,
        name: modelState?.name,
        primary_key: modelState?.primaryKey || undefined,
        connection_id: source?.id,
        slug: modelState?.slug,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        columns: { data: columns.map(({ name, type }) => ({ name, type })) },
        destination_instances: { data: [] },
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
      },
    });

    const id = data.insert_segments_one?.id;

    if (isEvent && id) {
      await createEvent({
        input: {
          model_id: id,
          timestamp_column: modelState?.timestampColumn,
        },
      });
    }

    if (isParentModel) {
      await updateObject({
        id,
        input: {
          visual_query_primary_label: primaryLabel,
          visual_query_secondary_label: secondaryLabel,
        },
      });

      // Enable suggestions with weekly refresh interval by default upon model creation.
      await updateModelColumns({
        id,
        input: {
          top_k_enabled: true,
          top_k_sync_interval: RefreshIntervals.Weekly,
        },
      });
    }

    setCreating(false);

    addToast(`${modelState?.name} created!`, {
      appearance: "success",
    });

    navigate(`/audiences/setup/${objectPath}`);
  };

  const steps: Step[] = [
    {
      title: "Select source",
      disabled: !source,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Sources
          </Heading>
          <SourcesGrid error={sourcesError} selection={source?.id} sources={supportedSources} onSelect={setSource} />
        </>
      ),
    },
    {
      pageSize: type ? "xlarge" : "medium",
      title: "Define",
      hideContinue: true,
      render: () => (
        <>
          {source && (
            <>
              {type ? (
                <ExploreWithSave
                  cancelQuery={cancelQuery}
                  columns={columns}
                  getSchema={getSchema}
                  isResultTruncated={Boolean(isResultTruncated)}
                  numRowsWithoutLimit={numRowsWithoutLimit}
                  rows={rows}
                  runQuery={runQuery}
                  saveLabel="Continue"
                  source={source}
                  type={type}
                  onSave={async () => {
                    setStep(step + 1);
                  }}
                  {...queryState}
                  error={queryError || sourcesError?.message}
                  errorAtLine={queryErrorAtLine}
                  loading={queryLoading || sourcesLoading}
                  rowsPerPage={15}
                  onDBTModelChange={setDBTModel}
                  onSQLChange={setSQL}
                  onTableChange={setTable}
                  onTypeChange={setType}
                />
              ) : (
                <QueryTypeSelect selected={type} source={source} onChange={setType} />
              )}
            </>
          )}
        </>
      ),
    },
    {
      pageSize: "medium",
      title: "Finish",
      disabled:
        !modelState?.name || (isParentModel && (!primaryLabel || !secondaryLabel)) || (isEvent && !modelState?.timestampColumn),
      loading: creating,
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
        return (
          <>
            <Heading sx={{ pb: 4, borderBottom: "small", mb: 12 }} variant="h1">
              Finalize your settings
            </Heading>
            <Row sx={{ width: "100%", pb: 4 }}>
              <Column sx={{ flex: 1, mr: 24 }}>
                <Heading sx={{ mb: 8 }} variant="h3">
                  {isParentModel ? "Name and primary key" : isEvent ? "Name and timestamp" : "Name"}
                </Heading>
                <Grid gap={6}>
                  <Field label="Name">
                    <Input placeholder="Enter a name" value={modelState?.name} onChange={(name) => setName(name)} />
                  </Field>
                  {isParentModel && (
                    <>
                      <Field label="Primary key">
                        <Select
                          options={columnOptions}
                          placeholder="Select a primary key"
                          value={modelState?.primaryKey}
                          onChange={({ value }) => {
                            setPrimaryKey(value);
                          }}
                        />
                      </Field>
                    </>
                  )}
                  {isEvent && (
                    <Field label="Timestamp">
                      <Select
                        options={columnOptions}
                        placeholder="Select a timestamp"
                        value={modelState?.timestampColumn}
                        onChange={({ value }) => setTimestampColumn(value)}
                      />
                    </Field>
                  )}
                  <Field label="Slug">
                    <Slug
                      available={Boolean(modelState.available)}
                      loading={modelState.loadingSlug}
                      placeholder={"your-model-slug"}
                      value={modelState.slug}
                      onChange={setSlug}
                    />
                  </Field>
                </Grid>
              </Column>
              <Grid gap={4} sx={{ color: "base.7", maxWidth: "420px" }}>
                <Column>
                  <Text sx={{ fontWeight: "bold" }}>Naming your {objectNameSingular}</Text>
                  <Text>
                    {isParentModel
                      ? "Users of audiences will see names of parent models when deciding which dataset they need to build on top of."
                      : isEvent
                      ? "Users of audiences will see event names when filtering by events."
                      : "Names of related models will be shown when building relationships on parent models."}
                  </Text>
                </Column>
                <Column>
                  <Text sx={{ fontWeight: "bold" }}>Example {objectNameSingular} names:</Text>
                  <ul>
                    {isParentModel ? (
                      <>
                        <li>All users</li>
                        <li>Users in San Francisco</li>
                      </>
                    ) : isEvent ? (
                      <>
                        <li>User signed up</li>
                        <li>Product purchased</li>
                      </>
                    ) : (
                      <>
                        <li>Devices</li>
                        <li>Purchases</li>
                      </>
                    )}
                  </ul>
                </Column>
                {isParentModel && (
                  <>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Primary key</Text>
                      <Text>Choose a primary key that is unique and consistent across queries.</Text>
                    </Column>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Example primary keys:</Text>
                      <ul>
                        <li>id</li>
                        <li>email</li>
                      </ul>
                    </Column>
                  </>
                )}
                {isEvent && (
                  <>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Timestamp</Text>
                      <Text>Choose a column that represents the timestamp of the event.</Text>
                    </Column>
                    <Column>
                      <Text sx={{ fontWeight: "bold" }}>Example timestamps:</Text>
                      <ul>
                        <li>created_at</li>
                        <li>purchased_at</li>
                      </ul>
                    </Column>
                  </>
                )}
              </Grid>
            </Row>
            {isParentModel && (
              <Row sx={{ width: "100%", pt: 12, borderTop: "small" }}>
                <Column sx={{ flex: 1, mr: 24 }}>
                  <Heading sx={{ mb: 8 }} variant="h3">
                    Content labels
                  </Heading>

                  <Grid gap={6}>
                    <Field label="Primary label">
                      <Select
                        options={columnOptions}
                        placeholder="Select a primary label"
                        value={primaryLabel}
                        onChange={({ value }) => {
                          setPrimaryLabel(value);
                        }}
                      />
                    </Field>

                    <Field label="Secondary label">
                      <Select
                        options={columnOptions}
                        placeholder="Select a secondary label"
                        value={secondaryLabel}
                        onChange={({ value }) => {
                          setSecondaryLabel(value);
                        }}
                      />
                    </Field>
                  </Grid>
                </Column>
                <Grid gap={4} sx={{ color: "base.7", maxWidth: "420px" }}>
                  <Column>
                    <Text sx={{ fontWeight: "bold" }}>Content labels</Text>
                    <Text>Content labels are used when previewing results from audiences that use this parent model.</Text>
                  </Column>
                  <Column sx={{ py: 4, px: 12, bg: "base.2", borderRadius: 1, my: 4 }}>
                    <Image src={labelsImage} />
                  </Column>
                  <Text>
                    For example, setting the primary label to the ”full_name” column and the secondary label to the “email”
                    column would yield:
                  </Text>
                  <Column sx={{ py: 4, px: 12, bg: "base.2", borderRadius: 1, mt: 4 }}>
                    <Image src={labelExampleImage} />
                  </Column>
                </Grid>
              </Row>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (source) {
      resetQueryState();
    }
  }, [source]);

  if (sourcesLoading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title={`Add a new ${objectNameSingular}`}
      onCancel={() => {
        navigate(`/audiences/setup/${objectPath}`);
      }}
      onSubmit={create}
    />
  );
};
