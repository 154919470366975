import { createContext, useContext, FC } from "react";

import { BaseFormkitContextType, useFormkitContext } from "src/formkit/components/formkit-context";

type Props = {
  errors: any;
  setErrors: (error: any) => void;
  config: any;
  setConfig: (config: any) => void;
  setCustomValidation: (object: { validate: (config: any) => Promise<{ yupError?: any; otherError?: any }> }) => void;
};

export type DestinationFormContextType = Props &
  BaseFormkitContextType & {
    hightouchColumns: any;
  };

export const DestinationFormContext = createContext<DestinationFormContextType>({} as DestinationFormContextType);

export const useDestinationForm = () => useContext(DestinationFormContext);

export const DestinationFormProvider: FC<Readonly<Props>> = ({
  children,
  config,
  errors,
  setConfig,
  setCustomValidation,
  setErrors,
}) => {
  const {
    columns,
    destination,
    destinationDefinition,
    loadingModel,
    loadingRows,
    model,
    reloadModel,
    reloadRows,
    rows,
    slug,
    sourceDefinition,
    sync,
    validate,
  } = useFormkitContext();

  return (
    <DestinationFormContext.Provider
      value={{
        config,
        destination,
        destinationDefinition,
        errors,
        hightouchColumns: columns,
        loadingModel,
        loadingRows,
        model,
        reloadModel,
        reloadRows,
        rows,
        setConfig,
        setCustomValidation,
        setErrors,
        slug,
        sourceDefinition,
        sync,
        validate,
      }}
    >
      {children}
    </DestinationFormContext.Provider>
  );
};
