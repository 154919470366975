import { useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useToasts } from "react-toast-notifications";
import { Text, Image, Grid } from "theme-ui";

import { DestinationForm } from "src/components/destinations/destination-form";
import { DestinationsGrid } from "src/components/destinations/destinations-grid";
import { ScheduleManager } from "src/components/schedule/schedule-manager";
import { Slug } from "src/components/slug";
import { useUser } from "src/contexts/user-context";
import {
  useCreateSyncMutation,
  useModelsAndAudiencesQuery,
  useModelQuery,
  useIsResourceSlugAvailableQuery,
  DestinationDefinitionFragment as DestinationDefinition,
  ModelQuery,
  DestinationQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { Section } from "src/ui/section";
import { useDestinations } from "src/utils/destinations";
import { isScheduleComplete } from "src/utils/schedule";
import { generateSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { ModelSelect } from "./model-select";

type CreateSyncWizard = {
  initialStep?: number;
  model?: ModelQuery["segments_by_pk"];
  destination?: DestinationQuery["destinations_by_pk"];
  destinationDefinition?: DestinationDefinition;
  onSubmit?: ({ id }: { id: string }) => void;
};

export const useCreateSyncWizard = ({
  initialStep = 0,
  model: onboardingModel,
  destination: onboardingDestination,
  destinationDefinition: onboardingDestinationDefinition,
  onSubmit,
}: CreateSyncWizard) => {
  const { data: params, loading: loadingParams } = useQueryString();
  const { addToast } = useToasts();
  const { user } = useUser();
  const [destination, setDestination] = useState<any>(onboardingDestination);
  const [destinationDefinition, setDestinationDefinition] = useState<DestinationDefinition | undefined>(
    onboardingDestinationDefinition,
  );
  const [selectedModelId, setSelectedModelId] = useState<string>(onboardingModel?.id);
  const [schedule, setSchedule] = useState<any>();
  const [slug, _setSlug] = useState<string>("");
  const [dirtySlug, setDirtySlug] = useState<boolean>(false);
  const [config, setConfig] = useState<any>();
  const { audiencesEnabled } = useFlags();

  const [step, setStep] = useWizardStepper(initialStep);

  const { data: modelsData, error: modelsError, isLoading: modelsLoading } = useModelsAndAudiencesQuery();
  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: selectedModelId,
    },
    { enabled: Boolean(selectedModelId) },
  );

  const {
    data: { destinations, definitions },
    loading: destinationsLoading,
  } = useDestinations();

  const { data, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "destination_instances",
      slug,
    },
    { enabled: Boolean(slug) },
  );
  const available = data?.isResourceSlugAvailable;

  const { isLoading: createLoading, mutateAsync: createSync } = useCreateSyncMutation();

  const models = modelsData?.segments;
  const model = modelData?.segments_by_pk;

  const { data: source, loading: loadingSource } = useSource(model?.connection?.id);

  const setSlug = (value) => {
    _setSlug(value);
    setDirtySlug(true);
  };

  useEffect(() => {
    if (!dirtySlug && model && destination) {
      const name = `${model.name} to ${destination.name}`;
      _setSlug(generateSlug(name));
    }
  }, [model, destination]);

  const create = async () => {
    const data = await createSync({
      object: {
        destination_id: destination.id,
        segment_id: model?.id,
        config: { ...config, configVersion: destinationDefinition?.configVersion },
        slug,
        schedule: schedule?.type === "manual" ? null : schedule,
        created_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    const id = data?.insert_destination_instances_one?.id;

    analytics.track("Sync Created", {
      destination_id: destination?.id,
      destination_name: destination?.name,
      destination_type: destinationDefinition?.name,
      model_id: model?.id,
      model_name: model?.name,
      schedule_type: schedule?.type,
    });

    addToast(`Sync created!`, {
      appearance: "success",
    });

    if (typeof onSubmit === "function" && id) {
      onSubmit({ id });
    }
  };

  useEffect(() => {
    if (models && !window.location.search) {
      setSelectedModelId(models[0]?.id);
    }
  }, [models]);

  useEffect(() => {
    if (params.model) {
      setSelectedModelId(params?.model);
    }
  }, [params]);

  useEffect(() => {
    if (step === 1) {
      analytics.track("Add Sync Model Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
      });
    }
    if (step === 2) {
      analytics.track("Add Sync Destination Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
        destination_type: destinationDefinition?.name,
      });
    }
  }, [step]);

  useEffect(() => {
    if (modelsError) {
      analytics.track("Model Error While Creating Sync", {
        model_id: model?.id,
        query_type: model?.query_type,
        error: `${modelsError}`,
      });
    }
  }, [modelsError]);

  const selectModelStep = {
    title: `Select model${audiencesEnabled ? " or audience" : ""}`,
    pageSize: "medium",
    render: () => (
      <>
        <Heading sx={{ mb: 8 }} variant="h3">
          Models{audiencesEnabled && " and audiences"}
        </Heading>

        <ModelSelect
          error={modelsError}
          model={model}
          modelLoading={modelLoading}
          models={models}
          selection={selectedModelId}
          onClick={(selection) => {
            setSelectedModelId(selection);
          }}
        />
      </>
    ),
  };

  const steps = [
    {
      title: "Select destination",
      pageSize: "medium",
      disabled: !destination,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Destinations
          </Heading>

          <DestinationsGrid
            definitions={definitions ?? []}
            destinations={destinations}
            selectedDestinationId={destination?.id}
            onSelect={({ destination, definition }) => {
              setDestination(destination);
              setDestinationDefinition(definition);
            }}
          />
        </>
      ),
    },
    {
      title: `Configure`,
      loading: false,
      continueProps: { form: "destination-form", type: "submit" },
      onContinue: () => {
        // empty function needed to set step after submit is triggered
      },
      render: () => (
        <>
          {destination && destinationDefinition && model && source?.definition && (
            <>
              <Row sx={{ alignItems: "center", mb: 8 }}>
                <Image alt={destinationDefinition.name} src={destinationDefinition.icon} width="24px" />
                <Heading sx={{ ml: 2 }} variant="h3">
                  Configure {destination.name || destinationDefinition.name}
                </Heading>
              </Row>
              <DestinationForm
                hideSave
                destination={destination}
                destinationDefinition={destinationDefinition}
                model={model}
                slug={destination.type}
                sourceDefinition={source.definition}
                onSubmit={(config) => {
                  setConfig(config);
                  setStep(step + 1);
                  return Promise.resolve();
                }}
              />
            </>
          )}
        </>
      ),
    },
    {
      title: "Finish",
      pageSize: "small",
      disabled: !isScheduleComplete(schedule) || !slug || !available,
      loading: createLoading,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Finalize your settings
          </Heading>
          <Grid gap={12}>
            <Message>
              <Text>
                For more information on triggering syncs via Airflow view our{" "}
                <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                  Airflow Operator docs
                </Link>
                .
              </Text>
              <Text sx={{ mt: 2 }}>
                If you need an API key you can create one <Link to="/settings/api-keys">here</Link>.
              </Text>
            </Message>

            <ScheduleManager schedule={schedule} setSchedule={setSchedule} />

            <Section title="Slug">
              <Slug
                available={Boolean(available)}
                loading={loadingSlug}
                placeholder={"your-sync-slug"}
                value={slug}
                onChange={setSlug}
              />
            </Section>
          </Grid>
        </>
      ),
    },
  ];

  return {
    createSync: create,
    loading: loadingParams || destinationsLoading || modelsLoading || loadingSource,
    setStep,
    step,
    steps: params?.model ? steps : [selectModelStep, ...steps],
  };
};
