import { VFC, useEffect } from "react";

import { useHeaderHeight } from "src/contexts/header-height-context";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { useCreateDestinationWizard } from "./use-create-destination-wizard";

export const CreateDestination: VFC = () => {
  const navigate = useNavigate();

  const [step, setStep] = useWizardStepper(0);

  const {
    data: { id: oauthDestinationId },
  } = useQueryString();

  if (oauthDestinationId && localStorage.getItem("onboardingOauth")) {
    localStorage.removeItem("onboardingOauth");
    navigate(`/onboarding?onboardingDestinationId=${oauthDestinationId}`);
  }

  const { headerHeight } = useHeaderHeight();

  const { createDestination, oAuthSuccess, steps, loading, definition } = useCreateDestinationWizard({
    step,
    setStep,
    onConnectClick: () => {
      localStorage.removeItem("onboardingOauth");
    },
    onSubmit: ({ id }) => navigate(`/destinations/${id}`),
    catalogSidebarTop: headerHeight + 36, // +  catalog top padding
  });

  useEffect(() => {
    if (step === 1 && definition) {
      analytics.track("Destination Catalog Continue Clicked", {
        destination_name: definition.name,
        destination_slug: definition.type,
        destination_status: definition.status,
      });
    }
  }, [step]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      previousDisabled={oAuthSuccess}
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new destination"
      onCancel={() => {
        navigate("/destinations");
      }}
      onSubmit={createDestination}
    />
  );
};
