import { VFC } from "react";

import { upperCase } from "lodash";

import { SelectCredential } from "src/components/credentials";
import { SourceDefinition } from "src/graphql";
import { Field } from "src/ui/field";

type CredentialSectionProps = {
  definition: SourceDefinition;
  matchingSource: Record<string, any>;
  credentialId?: any;
  setCredentialId?: any;
  error?: any;
  source?: any;
};

export const CredentialSection: VFC<Readonly<CredentialSectionProps>> = ({ matchingSource, ...props }) => {
  if (!["gcp", "aws"].includes(matchingSource?.provider)) {
    return null;
  }

  return (
    <Field label={`${upperCase(matchingSource.provider)} Credentials`}>
      <SelectCredential
        provider={matchingSource.provider}
        value={props.credentialId}
        onChange={(value) => props.setCredentialId(value)}
      />
    </Field>
  );
};
