import { FC } from "react";

import { Box } from "src/ui/box";

export const Sidebar: FC = ({ children }) => {
  return (
    <Box sx={{ minWidth: "280px", maxWidth: "280px", borderLeft: "small", pl: 8, position: "sticky", top: 24, ml: 10 }}>
      {children}
    </Box>
  );
};
