import { FC, useEffect, Fragment, ReactNode, useState } from "react";

import { Box, Text, ThemeUIStyleObject } from "theme-ui";

import { Header, PageContainer, Topbar } from "src/components/layout";
import { Props as ContainerProps } from "src/components/layout/page-container";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";

import { Modal } from "../modal";
import { StepDot } from "../stepdot";
import { colors } from "./../../../../design/colors";

type WizardOnboardingProps = {
  title: string;
  step: number;
  steps: Step[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  header?: ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
};

export type Step = {
  title: string;
  render: FC;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
  disabledText?: string;
  loading?: boolean;
  pageSize?: string;
  // Called when there are steps left on the flow
  onContinue?: () => void;
  // Called when the flow is completed
  onFinish?: () => void;
  containerSx?: ThemeUIStyleObject;
  pageSx?: ThemeUIStyleObject;
  continueLabel?: string;
  continueProps?: Record<string, string>;
  hideContinue?: boolean;
};

export const WizardOnboarding: FC<Readonly<WizardOnboardingProps>> = ({ title, step, steps, setStep, onSubmit, onCancel }) => {
  const data = steps[step];
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  return (
    <Fragment>
      <Column sx={{ width: "100%", alignItems: "center", height: "100%" }}>
        <Header>
          <Topbar>
            <Text sx={{ fontSize: 2, fontWeight: "bold", flexShrink: 0 }}>{title}</Text>
            <Row sx={{ flexShrink: 0 }}>
              <Button
                sx={{ mr: 2 }}
                variant="secondary"
                onClick={() => {
                  if (steps.length > 1) {
                    setModalOpen(true);
                  } else {
                    setModalOpen(false);
                    onCancel();
                  }
                }}
              >
                Cancel
              </Button>
              {!data?.hideContinue && (
                <Button
                  disabled={data?.disabled}
                  loading={data?.loading}
                  onClick={(event) => {
                    if (data?.continueProps?.type !== "submit") {
                      event.preventDefault();
                    }
                    if (step < steps.length - 1) {
                      if (typeof data?.onContinue === "function") {
                        data.onContinue();
                      } else {
                        setStep(step + 1);
                      }
                    } else {
                      if (typeof data?.onFinish === "function") {
                        data.onFinish();
                      }

                      onSubmit();
                    }
                  }}
                  {...(data?.continueProps || {})}
                >
                  {data?.continueLabel
                    ? data?.continueLabel
                    : steps.length === 1
                    ? "Select"
                    : step === steps.length - 1
                    ? "Finish"
                    : "Continue"}
                </Button>
              )}
            </Row>
          </Topbar>
        </Header>
        {steps.length > 1 && (
          <Row
            sx={{
              width: "100%",
              height: "40px",
              minHeight: "40px",
              background: colors.base[1],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: colors.base[5],
            }}
          >
            {steps.map(({ title }, index) => {
              return (
                <Fragment key={index}>
                  <StepDot active={index === step} complete={index < step} disabled={false} label={title} />
                  {index < steps?.length - 1 && <Box sx={{ width: "24px", height: "1px", bg: colors.base[4], ml: 1 }} />}
                </Fragment>
              );
            })}
          </Row>
        )}
        <Box sx={{ width: "100%", flexGrow: 1, overflowY: "scroll", pt: step === 0 && title.includes("destination") ? 0 : 6 }}>
          <PageContainer size={(data?.pageSize || "medium") as ContainerProps["size"]}>
            <Column sx={{ flexDirection: "column", width: "100%", ...(data?.sx || {}) }}>{data?.render({})}</Column>
          </PageContainer>
        </Box>
      </Column>
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setModalOpen(false);
                onCancel();
              }}
            >
              Exit
            </Button>
          </>
        }
        isOpen={modalOpen}
        title="Exit Setup?"
        onClose={() => setModalOpen(false)}
      >
        <Text>Are you sure you want to exit the setup? Any changes to your current step of the setup will be lost.</Text>
      </Modal>
    </Fragment>
  );
};
