import { FC, ReactNode, useCallback, useMemo } from "react";

import { ThemeUIStyleObject, Text, Label, Flex } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";

import { Box } from "../box";
import { CheckboxIcon } from "../icons";

export interface CheckboxProps {
  label?: string | ReactNode;
  description?: string | ReactNode;
  value: boolean;
  onChange?: (value: boolean) => void;
  sx?: ThemeUIStyleObject;
  size?: "small" | "large";
  disabled?: boolean;
  required?: boolean;
}

export const Checkbox: FC<Readonly<CheckboxProps>> = ({
  label,
  description,
  value,
  onChange,
  size = "small",
  sx = {},
  ...props
}) => {
  const permission = usePermission();

  const disabled = props?.disabled || permission?.unauthorized;

  const style: ThemeUIStyleObject = useMemo(
    () => ({
      cursor: disabled ? "default" : "pointer",
      opacity: disabled ? 0.5 : 1,
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      color: value ? "indigos.1" : "base.5",
      ":hover": {
        color: value || disabled ? undefined : "indigos.0",
      },
      pointerEvents: onChange ? undefined : "none",
      ...sx,
    }),
    [disabled, value, onChange, sx],
  );

  const handleClick = useCallback(() => {
    if (typeof onChange === "function") {
      onChange(!value);
    }
  }, [onChange, value]);

  return size === "small" ? (
    <Box sx={style} onClick={handleClick}>
      <CheckboxIcon
        checked={value}
        color={value ? "primary" : "base.3"}
        sx={{ ":hover": { svg: { fill: value ? undefined : "base.4" } } }}
      />
      {label && <Text sx={labelSx}>{label}</Text>}
    </Box>
  ) : (
    <Label
      sx={{
        display: "flex",
        alignItems: description ? "flex-start" : "center",
        cursor: disabled ? undefined : "pointer",
        borderRadius: 1,
        color: disabled ? "primaries.5" : value ? "primary" : "base.4",
        bg: value ? "primaries.0" : undefined,
        p: 2,
        width: "100%",
        ":hover": {
          bg: disabled ? undefined : "base.0",
        },
        flexShrink: 0,
        ...sx,
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) {
          handleClick();
        }
      }}
    >
      <CheckboxIcon
        checked={value}
        color={value ? "primary" : "base.3"}
        sx={{ ":hover": { svg: { fill: value ? undefined : "base.4" } }, mr: 2 }}
      />
      <Flex sx={{ flexDirection: "column" }}>
        <Text
          sx={{
            fontWeight: "bold",
            color: value && disabled ? "primaries.8" : value ? "primary" : disabled ? "base.2" : "black",
          }}
        >
          {label}
        </Text>
        {description && (
          <Text sx={{ whiteSpace: "pre-wrap", fontWeight: "body", mt: 1 }} variant="subtle">
            {description}
          </Text>
        )}
      </Flex>
    </Label>
  );
};

const labelSx = {
  ml: 2,
  color: "black",
};
