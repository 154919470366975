import { useState, VFC } from "react";

import moment from "moment";
import { Grid, Flex, Text, Image } from "theme-ui";

import { alertTypes } from "src/components/alerts";
import { AlertsOrderBy } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Section } from "src/ui/section";
import { Table } from "src/ui/table";
import { TableColumn } from "src/ui/table/table";

type Props = {
  thresholds: any;
  workspaceAlerts: any;
  alerts: any;
  orderBy: AlertsOrderBy | undefined;
  onAlertsChange: (alerts: any) => void;
  onSort: (sortKey: string) => void;
  onThresholdsChange: (thresholds: any) => void;
  showStatuses: boolean;
};

export enum WorkspaceAlertSortKeys {
  Name = "name",
  FatalError = "fatal_error_default",
  RowError = "row_error_default",
  LastAttempted = "alert_statuses_aggregate.min.last_attempted",
}

export const AlertSelector: VFC<Readonly<Props>> = ({
  showStatuses,
  workspaceAlerts,
  alerts,
  orderBy,
  thresholds,
  onAlertsChange,
  onThresholdsChange,
  onSort,
}) => {
  const [alertError, setAlertError] = useState<string | undefined | null>();

  const setAlert = (alertId, errorType, state, defaultState) => {
    const alert = alerts?.[alertId] || {};
    if (defaultState) {
      alert[errorType] = state ? null : false;
    } else {
      alert[errorType] = state ? true : null;
    }

    onAlertsChange({ ...alerts, [alertId]: alert });
  };

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(WorkspaceAlertSortKeys.Name),
      cell: ({ name, type }) => (
        <Flex sx={{ alignItems: "center" }}>
          <Image src={alertTypes[type].icon} sx={{ mr: 2 }} width="18px" />
          <Text
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: "semi",
            }}
          >
            {name}
          </Text>
        </Flex>
      ),
    },
    {
      name: "Fatal error",
      sortDirection: orderBy?.fatal_error_default,
      onClick: () => onSort(WorkspaceAlertSortKeys.FatalError),
      cell: (workspaceAlert) => (
        <AlertState alerts={alerts} errorType="fatal" workspaceAlert={workspaceAlert} onChange={setAlert} />
      ),
    },
    {
      name: "Row error",
      sortDirection: orderBy?.row_error_default,
      onClick: () => onSort(WorkspaceAlertSortKeys.RowError),
      cell: (workspaceAlert) => (
        <AlertState alerts={alerts} errorType="row" workspaceAlert={workspaceAlert} onChange={setAlert} />
      ),
    },
  ];

  if (showStatuses) {
    columns.push({
      name: "Last attempted",
      key: "alert_statuses",
      sortDirection: orderBy?.alert_statuses_aggregate?.min?.last_attempted,
      onClick: () => onSort(WorkspaceAlertSortKeys.LastAttempted),
      cell: (statuses) => (
        <Badge
          sx={{ alignItems: "center" }}
          variant="base"
          onClick={
            statuses?.[0]?.error
              ? () => {
                  setAlertError(JSON.stringify(statuses?.[0]?.error));
                }
              : undefined
          }
        >
          <Circle color={statuses?.[0]?.last_attempted ? (statuses?.[0]?.error ? "red" : "green") : "gray"} radius="12px" />
          <Text sx={{ ml: 2 }}>
            {statuses?.[0]?.last_attempted ? moment(statuses?.[0]?.last_attempted).calendar() : "Not attempted"}
          </Text>
        </Badge>
      ),
    });
  }

  return (
    <>
      <Row sx={{ flex: 1, justifyContent: "space-between" }}>
        <Grid gap={12} sx={{ flexGrow: 1 }}>
          <Section title="Enable alerts">
            <Table
              columns={columns}
              data={workspaceAlerts}
              placeholder={{
                title: "No alerts",
                body: "Set up alerts in the Settings page.",
                error: "Alerts failed to load, please try again.",
              }}
              sx={{ maxHeight: "600px" }}
            />
          </Section>
          <Section>
            <Field
              description="Row alerts will only be triggered if the error percentages are above both of the thresholds."
              label="Set row alert thresholds"
              size="large"
            >
              <Grid gap={3} sx={{ overflow: "auto" }}>
                <Field inline label={"Errors as % of total query rows"} labelSx={{ width: "300px" }}>
                  <Input
                    min="0"
                    placeholder="Enter a threshold percentage (default: 0%)..."
                    sx={{ width: "360px", ml: "auto" }}
                    type="number"
                    value={thresholds?.total}
                    onChange={(threshold) => {
                      onThresholdsChange({
                        ...thresholds,
                        total: threshold || threshold === 0 ? Number(threshold) : null,
                      });
                    }}
                  />
                </Field>
                <Field inline label={"Errors as % of synced rows"} labelSx={{ width: "300px" }}>
                  <Input
                    min="0"
                    placeholder="Enter a threshold percentage (default: 0%)..."
                    sx={{ width: "360px", ml: "auto" }}
                    type="number"
                    value={thresholds?.attempted}
                    onChange={(threshold) => {
                      onThresholdsChange({
                        ...thresholds,
                        attempted: threshold || threshold === 0 ? Number(threshold) : null,
                      });
                    }}
                  />
                </Field>
              </Grid>
            </Field>
          </Section>
        </Grid>
      </Row>

      <Modal
        footer={
          <Button variant="secondary" onClick={() => setAlertError(null)}>
            Close
          </Button>
        }
        isOpen={Boolean(alertError)}
        title="Error"
        onClose={() => setAlertError(null)}
      >
        <Text sx={{ whiteSpace: "pre-line" }}>{alertError}</Text>
      </Modal>
    </>
  );
};

const AlertState = ({ alerts, onChange, workspaceAlert: { id, row_error_default, fatal_error_default }, errorType }) => {
  const defaultState = {
    row: row_error_default,
    fatal: fatal_error_default,
  };

  return (
    <Flex sx={{ alignItems: "center" }}>
      <Checkbox
        value={alerts?.[id]?.[errorType] ?? defaultState[errorType]}
        onChange={(state) => {
          onChange(id, errorType, state, defaultState[errorType]);
        }}
      />
      {(alerts?.[id]?.[errorType] === null || alerts?.[id]?.[errorType] === undefined) && defaultState[errorType] && (
        <Text sx={{ color: "base.4", fontSize: 1 }}>(by default)</Text>
      )}
      {alerts?.[id]?.[errorType] === false && defaultState[errorType] && (
        <Text sx={{ color: "base.4", fontSize: 1 }}>(overriding default)</Text>
      )}
    </Flex>
  );
};
