import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const MonitoringIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 30 32">
      <path
        d="M28.0167 2.57715L23.1356 8.27172L17.4411 5.01768L12.56 10.7123L6.86544 7.45821L1.98438 11.5258"
        stroke="#4788C7"
        strokeMiterlimit="10"
      />
      <path
        d="M1.98491 10.3057C1.66128 10.3057 1.3509 10.4342 1.12206 10.6631C0.893212 10.8919 0.764648 11.2023 0.764648 11.5259C0.764648 11.8496 0.893212 12.1599 1.12206 12.3888C1.3509 12.6176 1.66128 12.7462 1.98491 12.7462C2.30855 12.7462 2.61893 12.6176 2.84777 12.3888C3.07662 12.1599 3.20518 11.8496 3.20518 11.5259C3.20518 11.2023 3.07662 10.8919 2.84777 10.6631C2.61893 10.4342 2.30855 10.3057 1.98491 10.3057Z"
        fill="#6B7280"
      />
      <path
        d="M1.98444 10.7129C2.43269 10.7129 2.79795 11.0782 2.79795 11.5264C2.79795 11.9747 2.43269 12.3399 1.98444 12.3399C1.5362 12.3399 1.17093 11.9747 1.17093 11.5264C1.17093 11.0782 1.5362 10.7129 1.98444 10.7129ZM1.98444 9.89941C1.08551 9.89941 0.357422 10.6275 0.357422 11.5264C0.357422 12.4254 1.08551 13.1535 1.98444 13.1535C2.88337 13.1535 3.61146 12.4254 3.61146 11.5264C3.61146 10.6275 2.88337 9.89941 1.98444 9.89941Z"
        fill="#4788C7"
      />
      <path
        d="M6.86577 6.23828C6.54214 6.23828 6.23176 6.36684 6.00292 6.59569C5.77407 6.82453 5.64551 7.13491 5.64551 7.45855C5.64551 7.78218 5.77407 8.09256 6.00292 8.3214C6.23176 8.55025 6.54214 8.67881 6.86577 8.67881C7.18941 8.67881 7.49979 8.55025 7.72863 8.3214C7.95748 8.09256 8.08604 7.78218 8.08604 7.45855C8.08604 7.13491 7.95748 6.82453 7.72863 6.59569C7.49979 6.36684 7.18941 6.23828 6.86577 6.23828Z"
        fill="#98CCFD"
      />
      <path
        d="M6.86628 6.64554C7.31452 6.64554 7.67979 7.01081 7.67979 7.45905C7.67979 7.9073 7.31452 8.27256 6.86628 8.27256C6.41803 8.27256 6.05277 7.9073 6.05277 7.45905C6.05277 7.01081 6.41803 6.64554 6.86628 6.64554ZM6.86628 5.83203C5.96735 5.83203 5.23926 6.56012 5.23926 7.45905C5.23926 8.35798 5.96735 9.08607 6.86628 9.08607C7.76521 9.08607 8.4933 8.35798 8.4933 7.45905C8.4933 6.56012 7.76521 5.83203 6.86628 5.83203Z"
        fill="#4788C7"
      />
      <path
        d="M12.5611 9.49219C12.2375 9.49219 11.9271 9.62075 11.6982 9.84959C11.4694 10.0784 11.3408 10.3888 11.3408 10.7125C11.3408 11.0361 11.4694 11.3465 11.6982 11.5753C11.9271 11.8042 12.2375 11.9327 12.5611 11.9327C12.8847 11.9327 13.1951 11.8042 13.4239 11.5753C13.6528 11.3465 13.7814 11.0361 13.7814 10.7125C13.7814 10.3888 13.6528 10.0784 13.4239 9.84959C13.1951 9.62075 12.8847 9.49219 12.5611 9.49219Z"
        fill="#98CCFD"
      />
      <path
        d="M12.5606 9.89847C13.0089 9.89847 13.3741 10.2637 13.3741 10.712C13.3741 11.1602 13.0089 11.5255 12.5606 11.5255C12.1124 11.5255 11.7471 11.1602 11.7471 10.712C11.7471 10.2637 12.1124 9.89847 12.5606 9.89847ZM12.5606 9.08496C11.6617 9.08496 10.9336 9.81305 10.9336 10.712C10.9336 11.6109 11.6617 12.339 12.5606 12.339C13.4595 12.339 14.1876 11.6109 14.1876 10.712C14.1876 9.81305 13.4595 9.08496 12.5606 9.08496Z"
        fill="#4788C7"
      />
      <path
        d="M17.441 3.79785C17.2807 3.79785 17.122 3.82941 16.974 3.89074C16.8259 3.95206 16.6914 4.04195 16.5781 4.15526C16.4648 4.26857 16.3749 4.40309 16.3136 4.55114C16.2523 4.69919 16.2207 4.85787 16.2207 5.01812C16.2207 5.17836 16.2523 5.33704 16.3136 5.48509C16.3749 5.63314 16.4648 5.76766 16.5781 5.88097C16.6914 5.99429 16.8259 6.08417 16.974 6.1455C17.122 6.20682 17.2807 6.23838 17.441 6.23838C17.6012 6.23838 17.7599 6.20682 17.9079 6.1455C18.056 6.08417 18.1905 5.99429 18.3038 5.88097C18.4171 5.76766 18.507 5.63314 18.5683 5.48509C18.6297 5.33704 18.6612 5.17836 18.6612 5.01812C18.6612 4.85787 18.6297 4.69919 18.5683 4.55114C18.507 4.40309 18.4171 4.26857 18.3038 4.15526C18.1905 4.04195 18.056 3.95206 17.9079 3.89074C17.7599 3.82941 17.6012 3.79785 17.441 3.79785V3.79785Z"
        fill="#98CCFD"
      />
      <path
        d="M17.4415 4.20414C17.8897 4.20414 18.255 4.5694 18.255 5.01765C18.255 5.46589 17.8897 5.83116 17.4415 5.83116C16.9932 5.83116 16.628 5.46589 16.628 5.01765C16.628 4.5694 16.9932 4.20414 17.4415 4.20414ZM17.4415 3.39062C16.5425 3.39062 15.8145 4.11872 15.8145 5.01765C15.8145 5.91657 16.5425 6.64467 17.4415 6.64467C18.3404 6.64467 19.0685 5.91657 19.0685 5.01765C19.0685 4.11872 18.3404 3.39062 17.4415 3.39062Z"
        fill="#4788C7"
      />
      <path
        d="M23.1363 7.05176C22.976 7.05176 22.8174 7.08332 22.6693 7.14464C22.5213 7.20597 22.3867 7.29585 22.2734 7.40917C22.1601 7.52248 22.0702 7.657 22.0089 7.80505C21.9476 7.9531 21.916 8.11178 21.916 8.27202C21.916 8.43227 21.9476 8.59095 22.0089 8.739C22.0702 8.88705 22.1601 9.02157 22.2734 9.13488C22.3867 9.24819 22.5213 9.33808 22.6693 9.3994C22.8174 9.46073 22.976 9.49229 23.1363 9.49229C23.2965 9.49229 23.4552 9.46073 23.6033 9.3994C23.7513 9.33808 23.8858 9.24819 23.9991 9.13488C24.1125 9.02157 24.2023 8.88705 24.2637 8.739C24.325 8.59095 24.3565 8.43227 24.3565 8.27202C24.3565 8.11178 24.325 7.9531 24.2637 7.80505C24.2023 7.657 24.1125 7.52248 23.9991 7.40917C23.8858 7.29585 23.7513 7.20597 23.6033 7.14464C23.4552 7.08332 23.2965 7.05176 23.1363 7.05176V7.05176Z"
        fill="#98CCFD"
      />
      <path
        d="M23.1358 7.45804C23.5841 7.45804 23.9493 7.82331 23.9493 8.27155C23.9493 8.7198 23.5841 9.08506 23.1358 9.08506C22.6876 9.08506 22.3223 8.7198 22.3223 8.27155C22.3223 7.82331 22.6876 7.45804 23.1358 7.45804ZM23.1358 6.64453C22.2369 6.64453 21.5088 7.37262 21.5088 8.27155C21.5088 9.17048 22.2369 9.89857 23.1358 9.89857C24.0347 9.89857 24.7628 9.17048 24.7628 8.27155C24.7628 7.37262 24.0347 6.64453 23.1358 6.64453Z"
        fill="#4788C7"
      />
      <path
        d="M28.0171 1.35742C27.6935 1.35742 27.3831 1.48599 27.1543 1.71483C26.9254 1.94367 26.7969 2.25405 26.7969 2.57769C26.7969 2.90132 26.9254 3.2117 27.1543 3.44055C27.3831 3.66939 27.6935 3.79795 28.0171 3.79795C28.3408 3.79795 28.6512 3.66939 28.88 3.44055C29.1088 3.2117 29.2374 2.90132 29.2374 2.57769C29.2374 2.25405 29.1088 1.94367 28.88 1.71483C28.6512 1.48599 28.3408 1.35742 28.0171 1.35742Z"
        fill="#98CCFD"
      />
      <path
        d="M28.0167 1.76371C28.4649 1.76371 28.8302 2.12897 28.8302 2.57722C28.8302 3.02546 28.4649 3.39073 28.0167 3.39073C27.5684 3.39073 27.2032 3.02546 27.2032 2.57722C27.2032 2.12897 27.5684 1.76371 28.0167 1.76371ZM28.0167 0.950195C27.1177 0.950195 26.3896 1.67829 26.3896 2.57722C26.3896 3.47614 27.1177 4.20424 28.0167 4.20424C28.9156 4.20424 29.6437 3.47614 29.6437 2.57722C29.6437 1.67829 28.9156 0.950195 28.0167 0.950195Z"
        fill="#4788C7"
      />
      <path d="M25.9834 11.9326H29.2374V30.6433H25.9834V11.9326Z" fill="#DFF0FE" />
      <path
        d="M28.8302 12.3389V30.2361H26.3897V12.3389H28.8302ZM29.6437 11.5254H25.5762V31.0496H29.6437V11.5254Z"
        fill="#4788C7"
      />
      <path d="M16.2227 16.001H19.4767V30.6442H16.2227V16.001Z" fill="#DFF0FE" />
      <path
        d="M19.0695 16.4073V30.2369H16.6289V16.4073H19.0695ZM19.883 15.5938H15.8154V31.0504H19.883V15.5938Z"
        fill="#4788C7"
      />
      <path d="M11.3408 21.6953H14.5949V30.6439H11.3408V21.6953Z" fill="#DFF0FE" />
      <path
        d="M14.1876 22.1016V30.2367H11.7471V22.1016H14.1876ZM15.0011 21.2881H10.9336V31.0502H15.0011V21.2881Z"
        fill="#4788C7"
      />
      <path d="M6.45996 19.2539H9.714V30.643H6.45996V19.2539Z" fill="#DFF0FE" />
      <path
        d="M9.30678 19.6602V30.2358H6.86624V19.6602H9.30678ZM10.1203 18.8467H6.05273V31.0493H10.1203V18.8467Z"
        fill="#4788C7"
      />
      <path d="M1.57812 23.3223H4.83217V30.6439H1.57812V23.3223Z" fill="#DFF0FE" />
      <path
        d="M4.42494 23.7285V30.2366H1.98441V23.7285H4.42494ZM5.23845 22.915H1.1709V31.0501H5.23845V22.915Z"
        fill="#4788C7"
      />
      <path d="M21.1025 18.4414H24.3566V30.6441H21.1025V18.4414Z" fill="#DFF0FE" />
      <path
        d="M23.9494 18.8477V30.2368H21.5088V18.8477H23.9494ZM24.7629 18.0342H20.6953V31.0503H24.7629V18.0342Z"
        fill="#4788C7"
      />
    </Icon>
  );
};
