import {
  AudienceParentFragment,
  AudienceQuery,
  FilterableColumnFragment,
  RelationshipFragment,
  TraitDefinitionFragment,
} from "src/graphql";
import { Option } from "src/ui/select";

import {
  ColumnType,
  EventTraitColumn,
  RawColumn,
  RelatedColumn,
  TraitColumn,
} from "../../../backend/lib/query/visual/types/column";
import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  RootCondition,
  SegmentSetCondition,
  Window,
} from "../../../backend/lib/query/visual/types/condition";
import { IntervalUnit } from "../../../backend/lib/query/visual/types/interval";
import {
  BooleanOperator,
  JsonArrayOperator,
  NumberOperator,
  StringOperator,
  TimestampOperator,
} from "../../../backend/lib/query/visual/types/operator";
import { TraitType } from "../../../backend/lib/query/visual/types/trait-definitions";

type Audience = AudienceQuery["segments_by_pk"];

export type { VisualQueryFilter } from "../../../backend/lib/query/visual/types/filter";
export { IntervalUnit } from "../../../backend/lib/query/visual/types/interval";
export type { IntervalValue } from "../../../backend/lib/query/visual/types/interval";
export type { Operator } from "../../../backend/lib/query/visual/types/operator";
export { BooleanOperator, NumberOperator, StringOperator, TimestampOperator, TraitType, ConditionType, ColumnType };
export type {
  Audience,
  RelationshipFragment as Relationship,
  TraitDefinitionFragment as TraitDefinition,
  FilterableColumnFragment as FilterableColumn,
  AudienceParentFragment as AudienceParent,
  AndCondition,
  AndOrCondition,
  Condition,
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  RootCondition,
  SegmentSetCondition,
  Window,
  TraitColumn,
  RawColumn,
  RelatedColumn,
  EventTraitColumn,
};

const operatorToLabel = (operator: string) => {
  return operator
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim();
};

export const stringOperatorOptions = Object.keys(StringOperator).map((key) => ({
  label: operatorToLabel(key),
  value: StringOperator[key],
}));

export const numberOperatorOptions = Object.keys(NumberOperator).map((key) => ({
  label: operatorToLabel(key),
  value: NumberOperator[key],
}));

export const numberOfOperatorOptions = [
  {
    label: "exactly",
    value: NumberOperator.Equals,
  },
  {
    label: "not",
    value: NumberOperator.DoesNotEqual,
  },
  { label: "greater than", value: NumberOperator.GreaterThan },
  { label: "less than", value: NumberOperator.LessThan },
  { label: "greater than or equal to", value: NumberOperator.GreaterThanOrEqualTo },
  { label: "less than or equal to", value: NumberOperator.LessThanOrEqualTo },
];

export const eventOperatorOptions = [
  {
    label: "at least",
    value: NumberOperator.GreaterThanOrEqualTo,
  },
  { label: "at most", value: NumberOperator.LessThanOrEqualTo },
  { label: "exactly", value: NumberOperator.Equals },
];

export const segmentSetOperatorOptions = [
  {
    label: "included",
    value: true,
  },
  {
    label: "not included",
    value: false,
  },
];

export const timestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "within", value: TimestampOperator.Within },
  { label: "not within", value: TimestampOperator.NotWithin },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.Between },
];

export const windowOperatorOptions = [
  {
    value: TimestampOperator.Within,
    label: "within",
  },
  {
    value: TimestampOperator.NotWithin,
    label: "not within",
  },
  {
    value: TimestampOperator.Between,
    label: "between",
  },
];

export const timestampSuggestionsOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.Between },
];

export const booleanOperatorOptions = [
  { label: "equals", value: BooleanOperator.Equals },
  { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  { label: "exists", value: BooleanOperator.Exists },
  { label: "does not exist", value: BooleanOperator.DoesNotExist },
];

export const jsonArrayOperatorOptions = [{ label: "contains", value: JsonArrayOperator.Contains }];

export const IntervalOptions = [
  {
    value: IntervalUnit.Minute,
    label: "minute(s)",
  },
  {
    value: IntervalUnit.Hour,
    label: "hour(s)",
  },
  {
    value: IntervalUnit.Day,
    label: "day(s)",
  },
  {
    value: IntervalUnit.Week,
    label: "week(s)",
  },
  {
    value: IntervalUnit.Month,
    label: "month(s)",
  },
  {
    value: IntervalUnit.Year,
    label: "year(s)",
  },
];

export const OperatorOptions: Record<ColumnType, Array<Option>> = {
  [ColumnType.Boolean]: booleanOperatorOptions,
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.String]: stringOperatorOptions,
  [ColumnType.Timestamp]: timestampOperatorOptions,
  [ColumnType.Date]: timestampOperatorOptions,
  [ColumnType.JsonArrayStrings]: jsonArrayOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayOperatorOptions,
  // Not supported
  [ColumnType.Unknown]: stringOperatorOptions,
  [ColumnType.Json]: stringOperatorOptions,
  [ColumnType.Null]: stringOperatorOptions,
};

const referenceStringOperatorOptions = [
  {
    label: "equals",
    value: StringOperator.Equals,
  },
  {
    label: "does not equal",
    value: StringOperator.DoesNotEqual,
  },
];

const referenceTimestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
];

export const ReferencePropertyOperatorOptions: Record<ColumnType, Array<Option>> = {
  [ColumnType.Boolean]: [
    { label: "equals", value: BooleanOperator.Equals },
    { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  ],
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.String]: referenceStringOperatorOptions,
  [ColumnType.Timestamp]: referenceTimestampOperatorOptions,
  [ColumnType.Date]: referenceTimestampOperatorOptions,
  // Not supported
  [ColumnType.JsonArrayStrings]: jsonArrayOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayOperatorOptions,
  [ColumnType.Unknown]: referenceStringOperatorOptions,
  [ColumnType.Json]: referenceStringOperatorOptions,
  [ColumnType.Null]: referenceStringOperatorOptions,
};

export const ReferencePropertyDefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Before,
  [ColumnType.Date]: TimestampOperator.Before,
  // Not supported
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const DefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Exists,
  [ColumnType.Date]: TimestampOperator.Exists,
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  // Not supported
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const OperatorsWithoutValue = [
  StringOperator.Exists,
  NumberOperator.Exists,
  TimestampOperator.Exists,
  TimestampOperator.DoesNotExist,
  TimestampOperator.Anniversary,
];

export const IntervalOperators = [TimestampOperator.Within, TimestampOperator.NotWithin, TimestampOperator.Between];

export const AbsoluteRelativeTimestampOperators = [TimestampOperator.Between];

export const initialPropertyCondition: PropertyCondition = {
  type: ConditionType.Property,
  propertyType: null,
  property: null,
  operator: null,
  value: null,
};

export const initialEventCondition: EventCondition = {
  type: ConditionType.Event,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  eventModelId: null,
  relationshipId: null,
  subconditions: [],
};

export const initialNumberOfCondition: NumberOfCondition = {
  type: ConditionType.NumberOf,
  relationshipId: null,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  subconditions: [],
};

export const initialSetCondition: SegmentSetCondition = {
  type: ConditionType.SegmentSet,
  modelId: null,
  includes: true,
};

export const initialFunnelCondition: FunnelCondition = {
  type: ConditionType.Funnel,
  eventModelId: null,
  relationshipId: null,
  didPerform: false,
  subconditions: [],
};

export const MultiValueColumnTypes = [ColumnType.String, ColumnType.Number];

export const MultiValueOperators = [
  StringOperator.Equals,
  StringOperator.DoesNotEqual,
  StringOperator.Contains,
  StringOperator.DoesNotContain,
  NumberOperator.Equals,
  NumberOperator.DoesNotEqual,
];

export const FunnelValueOptions = [
  {
    label: "value",
    value: ConditionType.Property,
  },
  {
    label: "property",
    value: ConditionType.ReferenceProperty,
  },
];

export const TraitTypeOptions = [
  {
    label: "Sum",
    value: TraitType.Sum,
  },
  { label: "Count", value: TraitType.Count },
  {
    label: "Average",
    value: TraitType.Average,
  },
  {
    label: "Most frequent",
    value: TraitType.MostFrequent,
  },
  {
    label: "Least frequent",
    value: TraitType.LeastFrequent,
  },
  { label: "First", value: TraitType.First },
  {
    label: "Last",
    value: TraitType.Last,
  },
  {
    label: "SQL",
    value: TraitType.RawSql,
  },
];

export type AdditionalColumn = { alias: string; column: RelatedColumn };

export type ColumnReference = RawColumn | RelatedColumn | TraitColumn | EventTraitColumn;

export interface TraitCondition extends PropertyCondition {
  property: {
    type: "related";
    path: string[];
    column: {
      type: "trait";
      traitDefinitionId: string;
      conditions: PropertyCondition[];
    };
  };
}

export const getInitialTraitColumn = (trait: TraitDefinitionFragment) => ({
  type: "related",
  path: [trait.relationship.id],
  column: {
    type: "trait",
    traitDefinitionId: trait.id,
    conditions: [],
  },
});

export const isColumnReference = (property: unknown): property is ColumnReference => {
  return typeof property === "object";
};

export const isRelatedColumn = (property: string | RawColumn | RelatedColumn | null): property is RelatedColumn => {
  return Boolean(property) && typeof property === "object" && property?.type === "related";
};

export const isTraitColumn = (column: EventTraitColumn | TraitColumn | RawColumn): column is TraitColumn =>
  column.type === "trait";

export const isTraitCondition = (condition: Condition): condition is TraitCondition =>
  condition.type === ConditionType.Property && isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);
