import { VFC, useState, ReactNode } from "react";

import { Text, Divider, Grid } from "theme-ui";

import { Sidebar } from "src/components/page";
import { Row, Box } from "src/ui/box";
import { ChatMonoIcon, BookIcon, TeammateIcon, ComplianceIcon } from "src/ui/icons";
import { IconProps } from "src/ui/icons/icon";
import { Link } from "src/ui/link";

import { InviteFormModal } from "../modals/invite-modal";

type Props = {
  buttons?: ReactNode;
  name: string;
  docsUrl: string;
  hideInviteTeammate?: boolean;
  hideSendMessage?: boolean;
  hideCompliance?: boolean;
  invite?: string;
  message?: string;
};

export const SidebarForm: VFC<Readonly<Props>> = ({
  buttons,
  name,
  docsUrl,
  hideSendMessage,
  hideInviteTeammate,
  hideCompliance,
  invite,
  message,
}) => {
  const [openInvite, setOpenInvite] = useState(false);

  const docsLink = docsUrl.startsWith("http") ? docsUrl : `${import.meta.env.VITE_DOCS_URL}/${docsUrl}`;

  return (
    <Sidebar>
      <Box>
        {buttons && (
          <Grid gap={3} mb={10}>
            {buttons}
          </Grid>
        )}
        <Block
          content={
            <>
              Not sure where to start? Read the{" "}
              <Link newTab to={docsLink}>
                documentation for {name}
              </Link>{" "}
              to get started.
            </>
          }
          icon={BookIcon}
          title="Read our docs"
        />
        <Block
          content={
            <>
              {invite || "If you are missing credentials or connection info"},{" "}
              <Link
                onClick={() => {
                  setOpenInvite(true);
                }}
              >
                invite a teammate
              </Link>{" "}
              to Hightouch so they can help.
            </>
          }
          hide={hideInviteTeammate}
          icon={TeammateIcon}
          title="Invite a teammate"
        />
        <Block
          content={
            <>
              We’re here to help.{" "}
              <Link onClick={() => window["Intercom"]?.("showNewMessage", message || "I could use some help")}>
                Send a message
              </Link>{" "}
              to one of our product specialists.
            </>
          }
          hide={hideSendMessage}
          icon={ChatMonoIcon}
          title="Send us a message"
        />
        <Block
          content={
            <>
              Hightouch follows industry best practices for securely storing credentials. Hightouch is SOC II, HIPAA, and GDPR
              compliant. Learn more{" "}
              <Link newTab to="https://hightouch.io/docs/security/overview/#overview">
                here
              </Link>
              .
            </>
          }
          hide={hideCompliance}
          icon={ComplianceIcon}
          title="We are secure and compliant"
        />
      </Box>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </Sidebar>
  );
};

const Block: VFC<Readonly<{ icon: VFC<IconProps>; title: string; content: ReactNode; hide?: boolean }>> = ({
  icon: Icon,
  title,
  content,
  hide,
}) => {
  if (hide) return null;

  return (
    <Box sx={{ mb: 8, lineHeight: "20px" }}>
      <Row sx={{ alignItems: "center" }}>
        <Icon color="base.5" size={18} />
        <Text ml={2} sx={{ color: "base.6" }}>
          {title}
        </Text>
      </Row>
      <Divider sx={{ borderColor: "base.2" }} />
      <Box sx={{ fontSize: "12px" }}>{content}</Box>
    </Box>
  );
};
