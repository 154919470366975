import { createContext, useContext, FC, useState, SetStateAction, Dispatch } from "react";

export type HeaderHeightContextType = {
  headerHeight: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
};

const defaultContextValue = {
  headerHeight: 0,
  setHeaderHeight: () => {},
};

export const HeaderHeightContext = createContext<HeaderHeightContextType>(defaultContextValue);

export const useHeaderHeight = () => useContext(HeaderHeightContext);

export const HeaderHeightProvider: FC = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  return <HeaderHeightContext.Provider value={{ headerHeight, setHeaderHeight }}>{children}</HeaderHeightContext.Provider>;
};
