import { FC } from "react";

import { AnimatePresence } from "framer-motion";
import { ThemeUIStyleObject } from "theme-ui";

import { AnimatedBox } from "./animated-box";

type Props = {
  hidden?: boolean;
  sx?: ThemeUIStyleObject;
};

export const Fade: FC<Readonly<Props>> = ({ hidden, children, sx }) => {
  return (
    <AnimatePresence>
      {!hidden && (
        <AnimatedBox
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          sx={sx}
          transition={{ duration: 0.25 }}
        >
          {children}
        </AnimatedBox>
      )}
    </AnimatePresence>
  );
};
