import { VFC } from "react";

import { Box, CSSProperties, ThemeUIStyleObject } from "theme-ui";

import {
  SourceDefinitionFragment as SourceDefinition,
  DestinationDefinitionFragment as DestinationDefinition,
} from "src/graphql";
import { Badge } from "src/ui/badge";

import { Indices } from "../../../../design";

export type IntegrationBadgeProps =
  | {
      definition: SourceDefinition;
      isSample?: boolean;
      integrationType: "source";
    }
  | {
      definition: DestinationDefinition;
      isSample?: boolean;
      integrationType: "destination";
    };

export const IntegrationBadge: VFC<Readonly<IntegrationBadgeProps>> = ({ definition, integrationType, isSample = false }) => {
  const badgeStyles = integrationType === "source" ? sourceBadgeStyles : destinationBadgeStyles;

  const lastMonth: Date = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  if (definition.status === "released") {
    if (isSample && integrationType === "source") {
      return (
        <Box style={badgeStyles}>
          <Badge
            sx={caretStyles}
            tooltip={`This ${integrationType} is a preconfigured Postgres database containing sample data.`}
            tooltipSx={tooltipStyles}
            variant="orange"
          >
            Sample
          </Badge>
        </Box>
      );
    }

    if (typeof definition.releasedAt === "string" && definition.releasedAt.length > 0) {
      const releasedAt = new Date(definition.releasedAt);

      if (releasedAt > lastMonth) {
        return (
          <Box style={badgeStyles}>
            <Badge
              sx={caretStyles}
              tooltip={`This ${integrationType} was recently added to our catalog. Tell us what you think!`}
              tooltipSx={tooltipStyles}
              variant="indigo"
            >
              New
            </Badge>
          </Box>
        );
      }
    }

    if (typeof definition.updatedAt === "string" && definition.updatedAt.length > 0) {
      const updatedAt = new Date(definition.updatedAt);

      if (updatedAt > lastMonth) {
        return (
          <Box style={badgeStyles}>
            <Badge
              sx={caretStyles}
              tooltip={`This ${integrationType} was recently updated with new functionality.`}
              tooltipSx={tooltipStyles}
              variant="indigo"
            >
              Recently Updated
            </Badge>
          </Box>
        );
      }
    }
  }

  if (definition.status === "alpha") {
    return (
      <Box style={badgeStyles}>
        <Badge
          sx={caretStyles}
          tooltip={`This ${integrationType} is currently in development. Contact us to receive early access when it’s ready.`}
          tooltipSx={tooltipStyles}
          variant="base"
        >
          Private Preview
        </Badge>
      </Box>
    );
  }

  if (definition.status === "beta") {
    return (
      <Box style={badgeStyles}>
        <Badge
          sx={caretStyles}
          tooltip={`This ${integrationType} is in beta. Your workspace is invited to participate.`}
          tooltipSx={tooltipStyles}
          variant="pink"
        >
          Beta
        </Badge>
      </Box>
    );
  }

  return null;
};

const sourceBadgeStyles: CSSProperties = {
  position: "absolute",
  right: -8,
  top: -8,
};

const destinationBadgeStyles: CSSProperties = {
  position: "relative",
};

const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  lineHeight: 2,
  px: 3,
  ":hover": {
    "::before": {
      content: "''",
      position: "absolute",
      top: "-14px",
      left: "16px",
      width: "10px",
      height: "10px",
      bg: "white",
      transform: "rotate(45deg)",
      zIndex: Indices.Modal,
    },
  },
};
