import { VFC } from "react";

import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { ResourcePermissionGrant } from "src/graphql";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";

import { Permission } from "../permission";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { name: string; private: boolean }) => Promise<void>;
  loading: boolean;
};

export const CreateViewModal: VFC<Readonly<Props>> = ({ isOpen, onClose, onSave, loading }) => {
  const { addToast } = useToasts();
  const { register, control, handleSubmit, watch, reset } = useForm({ defaultValues: { name: "", private: true } });

  const name = watch("name");

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!name}
            loading={loading}
            onClick={handleSubmit(async (data) => {
              await onSave(data);
              reset();
              onClose();
              addToast(`Created ${data.name} view`, { appearance: "success" });
            })}
          >
            Save view
          </Button>
        </>
      }
      isOpen={isOpen}
      sx={{ width: "500px" }}
      title="Save view"
      onClose={onClose}
    >
      <Text sx={{ fontWeight: "semi", mb: 6, fontSize: 2 }}>Save your current filters as a persistent view</Text>
      <Field label="View name">
        <Input autoFocus {...register("name")} placeholder="Enter a name..." />
      </Field>
      <Permission permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Create] }]}>
        <Controller
          control={control}
          name="private"
          render={({ field }) => (
            <Checkbox
              label="Share view with workspace members"
              sx={{ mt: 4 }}
              value={!field.value}
              onChange={(val) => field.onChange(!val)}
            />
          )}
        />
      </Permission>
    </Modal>
  );
};
