import { VFC, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useObject } from "src/components/audiences/use-object";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { useObjectQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";
import { useSource } from "src/utils/sources";

export const AudienceObjectQuery: VFC = () => {
  const { audience_id: id } = useParams<{ audience_id?: string }>();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { objectPath, objectNameCapitalized } = useObject();
  const { queryState, initQueryState, setSQL, setDBTModel, setTable } = useQueryState();

  const { data: objectData, isLoading: objectLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id) },
  );

  const update = useUpdateQuery();

  const object = objectData?.segments_by_pk;
  const type = object?.query_type as QueryType;
  const { data: source, loading: sourceLoading } = useSource(object?.connection?.id);

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(type, object?.columns, {
    modelId: object?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const save = async (data) => {
    await update({ model: object, queryState, columns: data?.columns ?? rawColumns });

    addToast(`${objectNameCapitalized} updated!`, {
      appearance: "success",
    });
  };

  useEffect(() => {
    initQueryState(object);
  }, [object]);

  if (objectLoading || sourceLoading) {
    return <PageSpinner />;
  }

  return (
    <Page
      crumbs={[
        { label: "Audiences", link: "/audiences" },
        {
          label: objectNameCapitalized,
          link: `/audiences/setup/${objectPath}`,
        },
        {
          label: object?.name ?? "",
          link: `/audiences/setup/${objectPath}/${id}`,
        },
        {
          label: "Query",
        },
      ]}
      size="full"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <ExploreWithSave
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        reset={resetRunState}
        rows={rows}
        runQuery={runQuery}
        saveLabel="Save"
        source={source}
        type={type}
        onBack={() => navigate(`/audiences/setup/${objectPath}/${id}`)}
        onSave={save}
        {...queryState}
        rowsPerPage={15}
        onDBTModelChange={setDBTModel}
        onSQLChange={setSQL}
        onTableChange={setTable}
      />
    </Page>
  );
};
