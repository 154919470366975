import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const AudienceIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g opacity={0.4}>
        <path d="M11.94,9.24c2.55,0,4.59-2.06,4.59-4.62S14.49,0,11.94,0,7.35,2.05,7.35,4.62s2.04,4.62,4.59,4.62Z" />
        <path d="M21.91,5.69c.66-2.59-1.27-4.92-3.74-4.92-.27,0-.52,.03-.77,.08-.03,0-.07,.02-.09,.05-.02,.04,0,.09,.02,.12,.74,1.04,1.16,2.31,1.16,3.68s-.39,2.52-1.07,3.54c-.07,.1,0,.24,.12,.27,.17,.03,.35,.05,.53,.05,1.79,.05,3.4-1.11,3.85-2.86Z" />
        <path d="M6.59,.85c-.25-.05-.51-.08-.77-.08C3.36,.77,1.43,3.1,2.09,5.69c.44,1.75,2.05,2.91,3.84,2.86,.18,0,.36-.02,.53-.05,.12-.02,.19-.16,.12-.27-.68-1.01-1.07-2.23-1.07-3.54s.43-2.63,1.17-3.68c.02-.03,.04-.08,.02-.12-.02-.03-.06-.05-.09-.05Z" />
      </g>
      <g>
        <path d="M11.94,11.5c-3.76,0-6.94,.62-6.94,2.99s3.2,2.97,6.94,2.97,6.94-.61,6.94-2.99-3.2-2.97-6.94-2.97Z" />
        <path d="M23.79,11.8c-.33-.7-1.12-1.19-2.32-1.42-.57-.14-2.11-.34-3.54-.31-.02,0-.03,.02-.04,.03,0,.01,0,.04,.03,.05,.66,.33,3.22,1.76,2.9,4.78-.01,.13,.09,.24,.22,.22,.63-.09,2.25-.44,2.75-1.53,.28-.58,.28-1.24,0-1.82Z" />
        <path d="M2.53,10.38c-1.2,.24-2,.72-2.32,1.42-.28,.58-.28,1.25,0,1.82,.5,1.09,2.12,1.44,2.75,1.53,.13,.02,.23-.09,.22-.22-.32-3.02,2.23-4.45,2.9-4.78,.03-.02,.03-.04,.03-.05,0,0-.01-.02-.03-.03-1.43-.03-2.97,.17-3.54,.31Z" />
      </g>
    </Icon>
  );
};
