import { useState, VFC } from "react";

import { Image, Flex, Box, Container } from "theme-ui";

import { DiagramIllustrations, Popover, Tile, EducationModal } from "src/components/onboarding";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { PlusSmallIcon } from "src/ui/icons";
import { UseSourceResult, SourceBadges, SourceIcon } from "src/utils/sources";

import { fontSizes } from "../../../../design";
import { colors } from "../../../../design/colors";
import { OnboardingActions, OnboardingData } from "./onboarding-reducer";

type Source = NonNullable<UseSourceResult["data"]>;

type Props = {
  onboardingData: OnboardingData;
  handleOnboarding: (type) => void;
  onSetOnboardingData: (action: OnboardingActions) => void;
};

export const DiagramDeclaration: VFC<Props> = ({ onboardingData, onSetOnboardingData, handleOnboarding }) => {
  const sourceName = onboardingData.declareSource?.name;
  const destinationName = onboardingData.declareDestination?.name;
  const [educationModalOpen, setEducationModalOpen] = useState(false);

  const emptySource = { definition: onboardingData?.declareSource, is_demo: false } as Source;

  return (
    <>
      <Heading
        sx={{
          my: 10,
          fontSize: fontSizes[5],
          fontWeight: 600,
        }}
        variant="h1"
      >
        Select the tools you would like to sync
      </Heading>
      <Row sx={{ width: "100%" }}>
        <Container sx={{ maxWidth: "960px", p: 8 }}>
          <Flex
            sx={{
              justifyContent: "space-between",
              fontSize: fontSizes[2],
              fontWeight: 500,
              color: "base.7",
            }}
          >
            <Box sx={{ border: `2px solid ${colors.base[3]}`, width: "40%", borderRadius: 8 }}>
              <Tile
                active={!sourceName}
                badge={<SourceBadges source={emptySource} />}
                icon={
                  sourceName ? (
                    <>
                      <SourceIcon source={emptySource} sx={{ width: "36px" }} />
                    </>
                  ) : (
                    <PlusSmallIcon size={24} />
                  )
                }
                popoverAsTooltip={true}
                popoverContent="selectSource"
                showChange={Boolean(sourceName)}
                subtitle={sourceName ? "Source" : undefined}
                title={sourceName || "Select source"}
                onChangeClick={() => handleOnboarding("declareSource")}
                onClick={() => handleOnboarding("declareSource")}
              />
            </Box>
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: 8,
              }}
            >
              <Tile
                active={!onboardingData.declareDestination}
                icon={
                  destinationName ? (
                    <Image alt={destinationName} src={onboardingData?.declareDestination?.icon} width={36} />
                  ) : (
                    <PlusSmallIcon size={24} />
                  )
                }
                popoverAsTooltip={true}
                popoverContent="selectDestination"
                showChange={Boolean(destinationName)}
                subtitle={destinationName && "Destination"}
                title={destinationName ? destinationName : "Select destination"}
                onChangeClick={destinationName ? () => handleOnboarding("declareDestination") : undefined}
                onClick={() => handleOnboarding("declareDestination")}
              />
            </Box>
          </Flex>
          <DiagramIllustrations />
          <Row>
            <Flex sx={{ width: "100%", justifyContent: "center", alignItems: "top", mt: 5 }}>
              <Box
                sx={{
                  p: "6px",
                  bg: !sourceName || !destinationName ? "transparent" : `rgba(72,40,237, 0.16)`,
                  borderRadius: 3,
                }}
              >
                <Popover content="createYourFirstSyncDisabled" disabled={Boolean(sourceName && destinationName)}>
                  <Button
                    disabled={!sourceName || !destinationName}
                    size={"large"}
                    sx={{
                      borderWidth: "2px",
                      cursor: !sourceName || !destinationName ? "not-allowed" : "pointer",
                    }}
                    variant={"primary"}
                    onClick={() => setEducationModalOpen(true)}
                  >
                    Create your first sync
                  </Button>
                </Popover>
              </Box>
            </Flex>
          </Row>
        </Container>
      </Row>
      <EducationModal
        destinationIcon={onboardingData?.declareDestination?.icon}
        destinationTitle={onboardingData?.declareDestination?.name}
        isOpen={educationModalOpen}
        sourceIcon={onboardingData?.declareSource?.icon}
        sourceTitle={onboardingData?.declareSource?.name}
        onCancel={() => setEducationModalOpen(false)}
        onConfirm={() => {
          onSetOnboardingData({ type: "set.declareComplete", complete: true });
          analytics.track("Workspace Onboarding Started", {
            source_type: onboardingData.declareSource?.name,
            destination_type: onboardingData.declareDestination?.name,
          });
          setEducationModalOpen(false);
        }}
      />
    </>
  );
};
