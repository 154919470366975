import { VFC } from "react";

import { Box } from "theme-ui";

import { Popout } from "src/ui/popout";

import { colors } from "../../../../design/colors";

export const popoverContent = {
  selectSource: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>A source is where your data is stored, usually a database, warehouse, or even a BI tool.</div>,
  },
  selectDestination: {
    variant: "secondary",
    arrowPosition: "left",
    text: (
      <div>
        A destination is where you want to send data to. Hightouch brings more than a hundred out-of-the-box destinations.
      </div>
    ),
  },
  connectSource: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <h5>Connect source</h5>
        Let’s connect the source you’ve selected! You will need to provide your credentials.
      </div>
    ),
  },
  connectDestination: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <h5>Connect destination</h5> Connect the destination to send data to. You will need to provide your credentials.
      </div>
    ),
  },
  setupModel: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <h5>Set up model</h5> Create a model used to query your source data. You'll get to choose from several ways to do this.
      </div>
    ),
  },
  createSync: {
    variant: "primary",
    arrowPosition: "center",
    text: (
      <div>
        <h5>Configure sync</h5>
        Finally, you're ready to configure your sync! You'll choose which fields get mapped from source to destination.
      </div>
    ),
  },
  runSync: {
    variant: "primary",
    arrowPosition: "center",
    text: <div>Hightouch is ready to send data to your destination! Go to your Sync and click Run.</div>,
  },
  modelDisabled: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>A model requires that you connect your source first. Complete that step then set up a model. </div>,
  },
  syncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: (
      <div>
        A data sync in Hightouch requires a connected data source, a model that selects the data, and a connected destination
        where we send the data.
      </div>
    ),
  },
  createYourFirstSyncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: <div>Select a sync and destination to get started.</div>,
  },
};

type Props = {
  content: keyof typeof popoverContent;
  children?: JSX.Element;
  footer?: JSX.Element;
  isOpen?: boolean;
  asTooltip?: boolean;
  disabled: boolean;
};

export const Popover: VFC<Props> = ({ children, footer, disabled, isOpen, content, asTooltip }) => {
  const text = popoverContent[content].text;
  const variant = popoverContent[content].variant;
  const arrowPosition = popoverContent[content].arrowPosition;

  return (
    <Popout
      portal
      content={
        <Box>
          <Box
            sx={{
              borderStyle: "solid",
              borderColor: variant === "secondary" ? colors.base[8] : colors.primary,
              borderWidth: "0 4px 4px 0",
              display: "inline-block",
              padding: "3px",
              transform: " rotate(-135deg)",
              position: "relative",
              top: "-10px",
              bg: variant === "secondary" ? colors.base[8] : colors.primary,
              left: arrowPosition === "center" ? "175px" : "30px",
            }}
          />

          <Box sx={{ fontSize: "14px", lineHeight: "24px", display: "flex", alignContent: "flex-start", p: 4, pt: 0 }}>
            <Box
              sx={{
                flexGrow: 1,
                h5: {
                  fontSize: "16px",
                  fontWeight: 600,
                  mt: 0,
                  mb: 3,
                },
              }}
            >
              {text}
              {footer && <Box>{footer}</Box>}
            </Box>
          </Box>
        </Box>
      }
      contentSx={{
        background: variant === "secondary" ? colors.base[8] : colors.primary,
        color: colors.white,
        borderRadius: 6,
        width: 360,
        shadow: 2,
        display: disabled ? "none" : "inline",
      }}
      ignoreClose={!asTooltip}
      isOpen={isOpen}
      placement="bottom"
    >
      {({ open, close }) => (
        <Box
          sx={{ cursor: disabled ? "default" : "pointer", width: "100%" }}
          onMouseEnter={() => asTooltip && open()}
          onMouseLeave={() => asTooltip && close()}
        >
          {children}
        </Box>
      )}
    </Popout>
  );
};
