import { FC, forwardRef } from "react";

import { Text, Image } from "theme-ui";

import { Row } from "../../ui/box";
import { NewSelect } from "../../ui/new-select";

const flags = {
  us: `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 7410 3900'%3e%3cpath fill='%23b22234' d='M0 0h7410v3900H0z'/%3e%3cpath d='M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0' stroke='%23fff' stroke-width='300'/%3e%3cpath fill='%233c3b6e' d='M0 0h2964v2100H0z'/%3e%3cg fill='%23fff'%3e%3cg id='d'%3e%3cg id='c'%3e%3cg id='e'%3e%3cg id='b'%3e%3cpath id='a' d='m247 90 70.534 217.082-184.66-134.164h228.253L176.466 307.082z'/%3e%3cuse xlink:href='%23a' y='420'/%3e%3cuse xlink:href='%23a' y='840'/%3e%3cuse xlink:href='%23a' y='1260'/%3e%3c/g%3e%3cuse xlink:href='%23a' y='1680'/%3e%3c/g%3e%3cuse xlink:href='%23b' x='247' y='210'/%3e%3c/g%3e%3cuse xlink:href='%23c' x='494'/%3e%3c/g%3e%3cuse xlink:href='%23d' x='988'/%3e%3cuse xlink:href='%23c' x='1976'/%3e%3cuse xlink:href='%23e' x='2470'/%3e%3c/g%3e%3c/svg%3e`,
  ie: `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 300'%3e%3cpath style='fill:%23169b62;fill-opacity:1;stroke:none' d='M0 752.362h200v300H0z' transform='translate(0 -752.362)'/%3e%3cpath style='fill:%23fff;fill-opacity:1;stroke:none' d='M200 752.362h200v300H200z' transform='translate(0 -752.362)'/%3e%3cpath style='fill:%23ff883e;fill-opacity:1;stroke:none' d='M400 752.362h200v300H400z' transform='translate(0 -752.362)'/%3e%3c/svg%3e`,
};

interface RegionOptionProps {
  friendlyName: string;
  internalName: string;
  flagCode: keyof typeof flags;
}

export const RegionOption: FC<RegionOptionProps> = ({ friendlyName, internalName, flagCode }) => {
  return (
    <Row sx={{ whiteSpace: "nowrap" }}>
      <Image src={flags[flagCode]} width="26px" />
      <Text sx={{ ml: 4, fontWeight: "bold" }}>{friendlyName}</Text>
      <Text color={"base.5"} sx={{ ml: 2 }}>
        {internalName}
      </Text>
    </Row>
  );
};

const regionOptions = [
  {
    value: "us-east-1",
    render: () => <RegionOption flagCode={"us"} friendlyName={"N. Virginia"} internalName={"us-east-1"} />,
  },
  {
    value: "eu-west-1",
    render: () => <RegionOption flagCode={"ie"} friendlyName={"Ireland"} internalName={"eu-west-1"} />,
  },
];

export const DisplayRegion = ({ region }) => {
  return (
    <Row
      sx={{
        position: "relative",
        alignItems: "center",
        pl: 3,
        pr: 9,
        minWidth: "100px",
        border: "small",
        borderColor: "base.2",
        borderRadius: 1,
        backgroundColor: "base.0",
        height: "32px",
        fontSize: 0,
        justifyContent: "space-between",
      }}
    >
      {regionOptions.find(({ value }) => value == region)?.render()}
    </Row>
  );
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export const SelectRegion = forwardRef<HTMLDivElement, Props>(({ value, onChange, disabled = false }, _ref) => {
  return <NewSelect disabled={disabled} options={regionOptions} value={value} onChange={onChange} />;
});

SelectRegion.displayName = "SelectRegion";
