import { VFC } from "react";

import { Grid, Text, Image } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { Selectable } from "src/ui/selectable";

type Props = {
  destinations: any;
  definitions: DestinationDefinition[];
  onSelect: any;
  selectedDestinationId: any;
};

export const DestinationsGrid: VFC<Readonly<Props>> = ({
  destinations,
  definitions,
  onSelect,
  selectedDestinationId = null,
}) => {
  return (
    <Grid columns="repeat(3, 1fr)" gap={4}>
      {destinations.map((destination: any) => {
        const definition = definitions?.find(({ type }) => type === destination?.type);

        if (!definition) {
          return null;
        }

        return (
          <Selectable
            key={destination.id}
            selected={destination.id === selectedDestinationId}
            sx={{ flexDirection: "row", alignItems: "center" }}
            onSelect={() => {
              onSelect({ destination, definition });
            }}
          >
            <Image
              alt={definition.name}
              src={definition.icon}
              sx={{ width: "24px", maxHeight: "100%", objectFit: "contain" }}
            />
            <Text sx={{ fontWeight: "bold", ml: 4, textOverflow: "ellipsis", overflow: "hidden" }}>
              {destination.name || definition.name}
            </Text>
          </Selectable>
        );
      })}
    </Grid>
  );
};
