import { FC, useEffect, Fragment } from "react";

import { Text } from "theme-ui";

import { Page } from "src/components/layout";
import { Props as ContainerProps } from "src/components/layout/page-container";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { CheckIcon } from "src/ui/icons";

export interface WizardProps {
  title: string;
  step: number;
  steps: Step[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export type Step = {
  title: string;
  render: FC;
  disabled?: boolean;
  disabledText?: string;
  loading?: boolean;
  pageSize?: string;
  // Called when there are steps left on the flow
  onContinue?: () => void;
  // Called when the flow is completed
  onFinish?: () => void;
  continueLabel?: string;
  continueProps?: Record<string, string>;
  hideContinue?: boolean;
};

export const Wizard: FC<Readonly<WizardProps>> = ({ title, step, steps, setStep, previousDisabled, onSubmit, onCancel }) => {
  const data = steps[step];

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  return (
    <Page
      header={
        <>
          <Text sx={{ fontSize: 2, fontWeight: "bold", flexShrink: 0 }}>{title}</Text>
          <Row sx={{ flex: 1, alignItems: "center", justifyContent: "center", mx: 8, display: ["none", "flex"] }}>
            {steps.map(({ title }, i) => {
              const visited = i <= step;
              const completed = i < step;
              return (
                <Fragment key={i}>
                  <Row
                    sx={{
                      alignItems: "center",
                      cursor: completed ? "pointer" : undefined,
                      pointerEvents: previousDisabled ? "none" : undefined,
                    }}
                    onClick={() => {
                      if (completed) {
                        setStep(i);
                      }
                    }}
                  >
                    <Row
                      sx={{
                        flexShrink: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        bg: visited ? "secondary" : "base.2",
                        width: "20px",
                        height: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      {i < step ? (
                        <CheckIcon color="white" size={12} strokeWidth="1px" />
                      ) : (
                        <Text
                          sx={{
                            fontSize: 0,
                            fontWeight: 600,
                            color: visited ? "white" : "dark.1",
                          }}
                        >
                          {i + 1}
                        </Text>
                      )}
                    </Row>
                    <Text
                      sx={{
                        ml: 3,
                        fontWeight: "bold",
                        color: visited ? "secondary" : "dark.1",
                        flexShrink: 0,
                        display: ["none", "none", "block", "block"],
                      }}
                    >
                      {title}
                    </Text>
                  </Row>

                  {i < steps?.length - 1 && <Row sx={{ height: "1px", bg: "base.3", flex: 1, mx: 2, maxWidth: "96px" }} />}
                </Fragment>
              );
            })}
          </Row>

          <Row sx={{ flexShrink: 0 }}>
            <Button sx={{ mr: 2 }} variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            {!data?.hideContinue && (
              <Button
                disabled={data?.disabled}
                loading={data?.loading}
                onClick={(event) => {
                  if (data?.continueProps?.type !== "submit") {
                    event.preventDefault();
                  }
                  if (step < steps.length - 1) {
                    if (data?.onContinue) {
                      data.onContinue();
                    } else {
                      setStep(step + 1);
                    }
                  } else {
                    if (data?.onFinish) {
                      data.onFinish();
                    }

                    onSubmit();
                  }
                }}
                {...(data?.continueProps || {})}
                tooltip={data?.disabled ? data?.disabledText : undefined}
              >
                {data?.continueLabel ? data?.continueLabel : step === steps.length - 1 ? "Finish" : "Continue"}
              </Button>
            )}
          </Row>
        </>
      }
      size={(data?.pageSize || "medium") as ContainerProps["size"]}
      sx={{ pb: title === "Add a new destination" ? 0 : 26 }}
    >
      {data?.render({})}
    </Page>
  );
};
