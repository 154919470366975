import { FC } from "react";

import Switch from "react-switch";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";

export interface ToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
}

export const Toggle: FC<Readonly<ToggleProps>> = ({ value, onChange, label, disabled = false, sx }) => {
  const permission = usePermission();

  return (
    <Flex sx={{ alignItems: "center", cursor: disabled ? "not-allowed" : "pointer", ...sx }}>
      {label && (
        <Text sx={{ textTransform: "uppercase", mr: 2, fontSize: "10px", color: "base.4", fontWeight: "bold" }}>{label}</Text>
      )}
      <Switch
        checked={value}
        checkedIcon={false}
        disabled={disabled || permission?.unauthorized}
        height={18}
        offColor="#A7AFBC"
        uncheckedIcon={false}
        width={32}
        onChange={onChange}
        onColor="#666EE8"
      />
    </Flex>
  );
};
