import { useCallback, useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";

import { Filter } from "src/components/filter";
import { useCreateViewMutation, useDeleteViewMutation, useUpdateViewMutation, useViewsQuery } from "src/graphql";
import { load, save } from "src/utils/storage";

type UseFilteringArguments = {
  /**
   * key used for fetching and saving custom views
   */
  viewKey: string;
};

export const useFiltering = ({ viewKey }: UseFilteringArguments) => {
  const localStorageKey = `${viewKey}-view`;

  const { addToast } = useToasts();

  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedView, setSelectedView] = useState("Default view");
  const [viewNotSaved, setViewNotSaved] = useState(false);

  const { data: viewsData } = useViewsQuery({
    resourceType: viewKey,
  });
  const views = viewsData?.resource_views ?? [];

  const { mutateAsync: createNewView, isLoading: creatingView } = useCreateViewMutation();
  const { mutateAsync: updateSavedView, isLoading: updatingView } = useUpdateViewMutation();
  const { mutateAsync: deleteSavedView } = useDeleteViewMutation();

  const updateFilters = useCallback((newFilters: Filter[]) => {
    setFilters(newFilters);
    setViewNotSaved(newFilters.length > 0);
  }, []);

  const resetViewFilters = useCallback(() => {
    const newFilters: Filter[] | undefined = views.find(({ name }) => name === selectedView)?.view;

    if (newFilters) {
      setFilters(newFilters);
    }
  }, [views]);

  const saveView = useCallback((viewName: string) => {
    save(localStorageKey, viewName);
    setSelectedView(viewName);
    setViewNotSaved(false);
  }, []);

  const selectView = useCallback(
    ({ view, filters: newFilters }: { view: string; filters: Filter[] }) => {
      setFilters(newFilters);
      saveView(view);
    },
    [saveView],
  );

  const createView = useCallback(
    async ({ name, private: privateView }: { name: string; private?: boolean }) => {
      try {
        await createNewView({ resourceType: viewKey, name, private: Boolean(privateView), view: filters });
      } catch (error) {
        addToast(`View could not be created. ${error.message}`, { appearance: "error" });
        return;
      }

      saveView(name);
    },
    [filters, saveView, viewKey],
  );

  const updateCurrentView = useCallback(async () => {
    const id = views.find(({ name }) => name === selectedView)?.id;
    await updateSavedView({ id, view: filters });
    setViewNotSaved(false);
  }, [filters, updateSavedView, views]);

  const deleteView = useCallback(
    async (args: { id: string }) => {
      try {
        await deleteSavedView(args);
        setSelectedView("Default view");
        setFilters([]);

        addToast("View successfully deleted.", {
          appearance: "success",
        });
      } catch (error) {
        addToast(`View could not be deleted. ${error.message}`, { appearance: "error" });
      }
    },
    [addToast, deleteSavedView],
  );

  useEffect(() => {
    // Set view saved in local storage on load
    const savedValue = load(localStorageKey);

    if (views && savedValue) {
      const savedView = views.find((view) => view.name === savedValue);

      if (savedView?.view) {
        saveView(savedValue);
        setFilters(savedView.view);
      }
    }
  }, [views]);

  return {
    state: {
      creatingView,
      filters,
      selectedView,
      viewNotSaved,
      views,
      updatingView,
    },
    actions: {
      createView,
      deleteView,
      resetViewFilters,
      selectView,
      updateCurrentView,
      updateFilters,
    },
  };
};
