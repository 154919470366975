import { useState, useEffect, VFC } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { map, compact, uniqBy } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Text } from "theme-ui";
import { useDebounce } from "use-debounce";
import { object, string, number } from "yup";

import { Slug } from "src/components/slug";
import { SelectRegion } from "src/components/workspaces/select-region";
import { useUser } from "src/contexts/user-context";
import {
  AvailableWorkspacesQuery,
  useAvailableWorkspacesQuery,
  useCreateWorkspaceMutation,
  useIsWorkspaceSlugAvailableQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useWorkspacesQuery,
  WorkspacesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Field } from "src/ui/field";
import { ChevronLeftIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";
import { useNavigate } from "src/utils/navigate";
import { generateSlug } from "src/utils/slug";
import { switchWorkspace } from "src/utils/workspaces";

const defaultRegion = "us-east-1";

const calculateOrganizations = (
  workspacesData: WorkspacesQuery | undefined,
  availableData: AvailableWorkspacesQuery | undefined,
) => {
  if (!workspacesData || !availableData) {
    return { defaultOrganization: null, orgs: [] };
  }
  const workspaces = workspacesData.workspaces;
  const orgs = uniqBy(
    compact([
      ...(workspaces || []).map((ws) => ws.organization),
      ...(availableData.getAvailableWorkspaces.joinable || []).map((ws) => ws.organization),
    ]),
    "id",
  );
  if (orgs.length === 1) {
    // Does the user only have one organization
    return {
      defaultOrganization: orgs[0],
      orgs: orgs.map((org) => ({ label: org.name, value: org.id })),
    };
  } else if (orgs.length > 1) {
    // Does the user have multiple organizations
    return {
      defaultOrganization: orgs[0],
      orgs: [{ label: "No Organization", value: null }, ...map(orgs, (org) => ({ label: org.name, value: org.id }))],
    };
  }
  return { defaultOrganization: null, orgs: [] };
};

export const NewWorkspace: VFC = () => {
  const { multiRegionEnabled } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as { partnerConnection: any } | undefined;
  const { user } = useUser();

  const { data: workspaceData } = useWorkspacesQuery();
  const { data: availableData } = useAvailableWorkspacesQuery();
  const { defaultOrganization, orgs } = calculateOrganizations(workspaceData, availableData);
  const multipleOrganization = Boolean(orgs.length);

  const { mutateAsync: linkWorkspace } = usePartnerConnectLinkWorkspaceMutation();
  const { mutateAsync: createWorkspace } = useCreateWorkspaceMutation();
  const validationSchema = object().shape({
    name: string().required("Name is required."),
    slug: string().required("Slug is required."),
    region: string().required("Region is required."),
    organization: number().nullable(),
  });
  const { register, control, handleSubmit, watch, formState, setValue } = useForm<{
    name: string;
    slug: string;
    region: string;
    organization: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: undefined,
      slug: undefined,
      region: defaultRegion,
      organization: defaultOrganization?.id,
    },
  });
  const { errors } = formState;

  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);

  const submit = async ({ name, slug, region, organization }) => {
    try {
      setIsCreatingWorkspace(true);

      const data = await createWorkspace({ name, slug, region, organization });
      analytics.track("New Workspace Created", {
        workspace_id: data?.createWorkspace?.id,
      });

      if (state?.partnerConnection) {
        await linkWorkspace({ uuid: state.partnerConnection.uuid, workspaceId: Number(data?.createWorkspace?.id) });
        navigate(`/partner-connect/${state.partnerConnection.uuid}`, { slug: false });
      } else {
        await switchWorkspace(data?.createWorkspace?.id, `/${slug}`);
      }
    } catch (error: unknown) {
      setIsCreatingWorkspace(false);
      throw error;
    }
  };

  // Watch name and autogenerate slugs.
  const name = watch("name");
  useEffect(() => {
    // Do not generate slug if the user has dirtied the field themselves.
    if (name && !formState.dirtyFields.slug) {
      const generatedSlug = generateSlug(name);
      setValue("slug", generatedSlug, { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    }
  }, [name]);

  const slug = watch("slug");
  const [debouncedSlug, { isPending: isPendingSlugCheck }] = useDebounce(slug, 300);

  const { data: availableSlugResponse, isLoading: slugLoading } = useIsWorkspaceSlugAvailableQuery(
    { slug: debouncedSlug ?? "" },
    { enabled: !isCreatingWorkspace && Boolean(debouncedSlug) },
  );

  const slugAvailable = Boolean(availableSlugResponse?.isWorkspaceSlugAvailable);

  if (user && user.not_member_of_current_workspace) {
    return (
      <Card>
        <Text sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}>Can not create a workspace while impersonating</Text>
        <Button
          iconBefore={<ChevronLeftIcon color={"base.5"} size={18} />}
          sx={{ flexGrow: 1 }}
          variant="text-secondary"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Card>
    );
  }

  return (
    <Fade>
      <Card sx={{ mx: "auto", width: "460px", maxWidth: "100%" }}>
        <Text sx={{ textAlign: "center", fontSize: 7, fontWeight: "bold", mb: 10 }}>New workspace</Text>

        <Field error={errors?.name?.message} label="Name">
          <Input autoFocus {...register("name")} placeholder="Acme Production" />
        </Field>

        <Field error={errors?.slug?.message} label="Slug" sx={{ mt: 9 }}>
          <Controller
            control={control}
            name="slug"
            render={({ field }) => (
              <Slug
                {...field}
                available={slugAvailable}
                loading={slugLoading || isPendingSlugCheck()}
                placeholder={"acme-production"}
              />
            )}
          />

          <Text color="base.5" sx={{ mt: 2, fontSize: 12 }}>
            app.hightouch.io/{slug}
          </Text>
        </Field>

        {multiRegionEnabled && (
          <Field error={errors?.region?.message} label={"Region"} sx={{ mt: 9 }}>
            <Controller control={control} name={"region"} render={({ field }) => <SelectRegion {...field} />} />
          </Field>
        )}
        {multipleOrganization && (
          <Field error={errors?.organization?.message} label={"Organization"} sx={{ mt: 9 }}>
            <Controller
              control={control}
              name={"organization"}
              render={({ field }) => (
                <NewSelect options={orgs} {...field} disabled={orgs.length === 1} onChange={(value) => field.onChange(value)} />
              )}
            />
          </Field>
        )}

        <Button
          disabled={slugLoading || isPendingSlugCheck() || !slugAvailable}
          loading={isCreatingWorkspace}
          size="large"
          sx={{ mt: 12, mb: 4 }}
          onClick={handleSubmit(submit)}
        >
          Create workspace
        </Button>

        <Button size="large" variant="white" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </Card>
    </Fade>
  );
};
