import { VFC } from "react";

import { Image, Flex, Box, Container, Divider } from "theme-ui";

import { DiagramIllustrations, Popover, Tile, TileDestination } from "src/components/onboarding";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ModelIcon, PlusSmallIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { UseSourceResult, SourceBadges, SourceIcon } from "src/utils/sources";

import { fontSizes } from "../../../../design";
import { colors } from "../../../../design/colors";
import { OnboardingData, OnboardingLoading } from "./onboarding-reducer";

type Source = NonNullable<UseSourceResult["data"]>;

type Props = {
  onboardingData: OnboardingData;
  onboardingLoading: OnboardingLoading;
  handleChangeClick: (type) => void;
  handleOnboarding: (type) => void;
};

export const DiagramOnboarding: VFC<Props> = ({ onboardingData, onboardingLoading, handleOnboarding, handleChangeClick }) => {
  const sourceName = onboardingData.declareSource?.name;
  const destinationName = onboardingData.declareDestination?.name;
  const currentStep = onboardingData.currentStep;
  const emptySource = { definition: onboardingData.declareSource || {}, is_demo: false } as Source;

  return (
    <>
      <Heading
        sx={{
          my: 10,
          fontSize: fontSizes[5],
          fontWeight: 600,
        }}
        variant="h1"
      >
        Create your first sync
      </Heading>
      <Row sx={{ width: "100%" }}>
        <Container sx={{ maxWidth: "960px", p: 8 }}>
          <Flex
            sx={{
              justifyContent: "space-between",
              fontSize: "16px",
              fontWeight: 500,
              color: colors.base[7],
            }}
          >
            <Box sx={{ border: `2px solid ${colors.base[3]}`, width: "40%", borderRadius: "8px" }}>
              {onboardingLoading.source ? (
                <TileSpinner height={80} />
              ) : (
                <Tile
                  active={currentStep === 1}
                  icon={
                    <>
                      <SourceIcon source={onboardingData.onboardingSource || emptySource} sx={{ width: "36px" }} />
                      <SourceBadges source={onboardingData.onboardingSource || emptySource} />
                    </>
                  }
                  popoverContent="connectSource"
                  popoverDisabled={currentStep > 1}
                  popoverIsOpen={currentStep === 1}
                  showChange={true}
                  subtitle={onboardingData?.onboardingSource && "Source"}
                  title={
                    onboardingData?.onboardingSource?.name ? onboardingData.onboardingSource.name : `Connect ${sourceName}`
                  }
                  onChangeClick={() => handleChangeClick("changeSource")}
                  onClick={() => handleOnboarding("connectSource")}
                />
              )}
              <Divider sx={{ margin: 0 }} />
              {onboardingLoading.model ? (
                <TileSpinner height={80} />
              ) : (
                <Tile
                  active={currentStep === 2}
                  icon={
                    onboardingData?.onboardingModel ? (
                      <ModelIcon size={36} sx={{ svg: { fill: "primary" } }} />
                    ) : (
                      <PlusSmallIcon size={24} />
                    )
                  }
                  popoverContent={currentStep === 2 ? "setupModel" : "modelDisabled"}
                  popoverDisabled={currentStep > 2}
                  popoverIsOpen={currentStep === 2}
                  showChange={currentStep > 2}
                  subtitle={onboardingData?.onboardingModel ? "Model" : undefined}
                  title={onboardingData?.onboardingModel ? onboardingData?.onboardingModel?.name : "Set up Model"}
                  onChangeClick={() => handleChangeClick("changeModel")}
                  onClick={() => handleOnboarding("setupModel")}
                />
              )}
            </Box>
            <Box
              sx={{
                border: `2px solid ${colors.base[3]}`,
                width: "40%",
                borderRadius: "8px",
              }}
            >
              {onboardingLoading.destination || !destinationName ? (
                <TileSpinner height={162} />
              ) : (
                <TileDestination
                  active={currentStep === 3}
                  disable={false}
                  icon={<Image alt={destinationName} src={onboardingData?.declareDestination?.icon} width="36px" />}
                  popoverContent="connectDestination"
                  popoverDisabled={currentStep !== 3}
                  popoverIsOpen={currentStep === 3}
                  showChange={currentStep > 1}
                  subtitle={onboardingData?.onboardingDestination ? "Destination" : undefined}
                  title={onboardingData?.onboardingDestination?.name || `Connect ${destinationName}`}
                  onChangeClick={() => handleOnboarding("changeDestination")}
                  onClick={() => handleOnboarding("connectDestination")}
                />
              )}
            </Box>
          </Flex>
          <DiagramIllustrations />
          <Row>
            <Flex sx={{ width: "100%", justifyContent: "center", alignItems: "top", mt: 5 }}>
              <Popover content={currentStep === 4 ? "createSync" : "syncDisabled"} disabled={false} isOpen={currentStep === 4}>
                <Box
                  sx={{
                    p: "6px",
                    bg: currentStep < 4 ? "transparent" : "primaries.3",
                    borderRadius: 3,
                  }}
                >
                  <Button
                    disabled={currentStep !== 4}
                    size="large"
                    variant={currentStep === 4 ? "primary" : "secondary"}
                    onClick={() => handleOnboarding("createSync")}
                  >
                    Configure sync
                  </Button>
                </Box>
              </Popover>
            </Flex>
          </Row>
        </Container>
      </Row>
    </>
  );
};

const TileSpinner: VFC<{ height: number }> = ({ height }) => (
  <Box
    sx={{
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      flex: "1",
      height: height,
      display: "flex",
    }}
  >
    <Spinner />
  </Box>
);
