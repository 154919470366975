import { VFC, useState, useCallback, useMemo } from "react";

import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications";
import { Image, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { destinationFilterDefinitions, Filters, getHasuaExpFromFilters } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  DestinationsBoolExp,
  DestinationsOrderBy,
  ResourcePermissionGrant,
  useDeleteDestinationsMutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Row, Column, Box } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { Tooltip } from "src/ui/tooltip";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Destinations: VFC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Update] },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const { labels } = useLabels();

  const { mutateAsync: updateDestination, isLoading: updatingDestination } = useUpdateDestinationV2Mutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteDestinationsMutation();

  const { onSort, orderBy } = useTableConfig<DestinationsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "destination" });

  const hasuraFilters: DestinationsBoolExp = useMemo(
    () => getHasuaExpFromFilters(destinationFilterDefinitions, filters),
    [filters],
  );

  const {
    data: { destinations: allDestinations, definitions: allDefinitions },
  } = useDestinations({ limit: 1000 });

  // used for filtering
  const allDestinationsWithDefinitionData = useMemo(() => {
    return (allDestinations ?? []).map((destination) => {
      const definition = (allDefinitions ?? []).find(({ type }) => type === destination.type);

      return { ...destination, definition };
    });
  }, [allDestinations, allDefinitions]);

  const {
    data: { destinations, definitions },
    loading: destinationsLoading,
    error: destinationsError,
    isRefetching,
  } = useDestinations({
    filters: hasuraFilters,
    destinationsOrderBy: orderBy,
  });

  const filteredDestinations = useMemo(() => {
    return search && destinations && definitions
      ? destinations.filter((destination) => {
          const lowerCaseSearch = search.toLowerCase();
          const definition = definitions.find(({ type }) => type === destination.type);
          return (
            destination.name?.toLowerCase().includes(lowerCaseSearch) ||
            definition?.name.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : destinations;
  }, [search, destinations, definitions]);

  const bulkAddLabels = async (labels: Record<string, string>) => {
    try {
      const promises = selectedRows.map((id) => updateDestination({ id: String(id), append: { tags: labels } }));
      await Promise.all(promises);

      setAddingLabels(false);
      onRowSelect([]);

      const labelCount = Object.keys(labels).length;
      addToast(
        `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "destination",
          selectedRows.length,
        )}`,
        {
          appearance: "success",
        },
      );
    } catch (error) {
      addToast("Failed to update labels for selected destinations.", {
        appearance: "error",
      });
      Sentry.captureException(error);
    }
  };

  const bulkDeleteDestinations = async () => {
    if (userCanDelete) {
      try {
        await bulkDelete({ ids: selectedRows.map(String) });
        onRowSelect([]);
      } catch (error) {
        addToast("Failed to delete destinations.", { appearance: error });
        Sentry.captureException(error);
      }
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, tags, type }) => {
          const definition = definitions?.find((definition) => definition.type === type);

          return (
            <Row sx={{ alignItems: "center" }}>
              <Tooltip text={name || definition?.name}>
                <Text
                  sx={{
                    fontWeight: "semi",
                    maxWidth: "350px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {name || definition?.name}
                </Text>
              </Tooltip>
              <LabelsCell labels={tags} />
            </Row>
          );
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort(SortKeys.Type),
        cell: ({ type }) => {
          const definition = definitions?.find((definition) => definition.type === type);
          return (
            <Row sx={{ alignItems: "center" }}>
              <Image
                alt={definition?.name}
                src={definition?.icon}
                sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
              />
              <Text sx={{ fontWeight: "semi" }}>{definition?.name}</Text>
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [definitions, orderBy],
  );

  const actions = [
    userCanUpdate ? { label: "Add labels", onClick: () => setAddingLabels(true) } : null,
    userCanDelete
      ? {
          label: "Delete",
          onClick: () => setConfirmingDelete(true),
          sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
        }
      : null,
  ].filter(isPresent);

  const placeholder = useMemo(
    () => ({
      title: "No destinations",
      body: search ? "" : "Add a destination to get started.",
      error: "Destinations failed to load, please try again.",
    }),
    [search],
  );

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a destination, upgrade your plan.";

  const onRowClick = useCallback(({ id }, event) => openUrl(`/destinations/${id}`, navigate, event), [navigate]);

  if (destinationsLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
        <Page crumbs={[{ label: "Destinations" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center" }}>
                <Heading sx={{ mr: 2 }}>Destinations</Heading>
                <Views
                  deletePermissionResource="destination"
                  value={selectedView}
                  views={views}
                  onChange={selectView}
                  onDelete={deleteView}
                />
                {viewNotSaved &&
                  (selectedView === "Default view" ? (
                    <Button
                      sx={{ ml: 2 }}
                      variant="purple"
                      onClick={() => {
                        setCreateViewModalOpen(true);
                      }}
                    >
                      Save as
                    </Button>
                  ) : (
                    <DropdownButton
                      loading={updatingView}
                      options={[
                        {
                          label: "Save as",
                          onClick: () => {
                            setCreateViewModalOpen(true);
                          },
                        },
                        {
                          label: "Reset changes",
                          onClick: () => {
                            resetViewFilters();
                          },
                        },
                      ]}
                      sx={{ ml: 2 }}
                      onClick={updateCurrentView}
                    >
                      Save
                    </DropdownButton>
                  ))}
              </Row>
              <Permission permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
                <Button
                  disabled={overageLockout}
                  tooltip={overageLockout ? overageText : undefined}
                  onClick={() => {
                    analytics.track("Add Destination Clicked");
                    navigate("/destinations/new");
                  }}
                >
                  Add destination
                </Button>
              </Permission>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                <SearchInput placeholder="Search destinations by name..." value={search ?? ""} onChange={setSearch} />

                <Filters
                  data={allDestinationsWithDefinitionData}
                  filterDefinitions={destinationFilterDefinitions}
                  filters={filters}
                  resourceType="destination"
                  sx={{ ml: 2 }}
                  onChange={updateFilters}
                />
              </Box>

              {actions.length > 0 && (
                <Fade hidden={selectedRows.length === 0} sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                      <Text as="span" sx={{ color: "base.5" }}>{`${pluralize(
                        "destination",
                        selectedRows.length,
                        true,
                      )} selected`}</Text>

                      <Menu options={actions}>
                        <Button
                          propagate
                          iconAfter={<ChevronDownIcon size={16} />}
                          loading={loadingBulkDelete}
                          sx={{ ml: 3 }}
                          variant="secondary"
                        >
                          Select action
                        </Button>
                      </Menu>
                    </Text>
                  </Box>
                </Fade>
              )}
            </Row>
          </Column>

          <Table
            columns={columns}
            data={filteredDestinations}
            error={Boolean(destinationsError) || Boolean(destinationsError)}
            loading={isRefetching}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={userCanDelete ? onRowSelect : undefined}
          />
        </Page>
      </PermissionProvider>
      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="destination"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteDestinations}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label destinations that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={updatingDestination}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("destination", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};
