import { VFC, useLayoutEffect, useRef, useState } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";

type Props = {
  label: { key: string; value: string | number };
  maxKeyWidth?: string;
  maxValueWidth?: string;
  sx?: ThemeUIStyleObject;
};

export const labelStyle: ThemeUIStyleObject = {
  alignItems: "center",
  bg: "base.2",
  borderRadius: 2,
  px: 2,
  height: "28px",
  width: "max-content",
  flexShrink: 0,
  fontSize: 0,
};

export const Label: VFC<Readonly<Props>> = ({ label, maxKeyWidth, maxValueWidth, sx = {} }) => {
  const keyRef = useRef<HTMLDivElement | null>(null);
  const valueRef = useRef<HTMLDivElement | null>(null);

  const [displayKeyTooltip, setDisplayKeyTooltip] = useState(false);
  const [displayValueTooltip, setDisplayValueTooltip] = useState(false);

  useLayoutEffect(() => {
    // only show key tooltip when content is hidden
    if (
      keyRef.current?.clientWidth !== undefined &&
      keyRef.current?.scrollWidth !== undefined &&
      keyRef.current?.clientWidth < keyRef.current?.scrollWidth
    ) {
      setDisplayKeyTooltip(true);
    } else {
      setDisplayKeyTooltip(false);
    }
  }, []);

  useLayoutEffect(() => {
    // only show value tooltip when content is hidden
    if (
      valueRef.current?.clientWidth !== undefined &&
      valueRef.current?.scrollWidth !== undefined &&
      valueRef.current?.clientWidth < valueRef.current?.scrollWidth
    ) {
      setDisplayValueTooltip(true);
    } else {
      setDisplayValueTooltip(false);
    }
  }, []);

  return (
    <Row
      sx={{
        ...labelStyle,
        ...sx,
      }}
    >
      <Tooltip disabled={!displayKeyTooltip} text={label.key}>
        <Text
          ref={keyRef}
          sx={{
            color: "primaries.8",
            fontWeight: "bold",
            maxWidth: maxKeyWidth || "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {label.key}
        </Text>
      </Tooltip>
      <Tooltip disabled={!displayValueTooltip} text={label.value.toString()}>
        <Text
          ref={valueRef}
          sx={{
            ml: 2,
            maxWidth: maxValueWidth || "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {String(label.value)}
        </Text>
      </Tooltip>
    </Row>
  );
};
