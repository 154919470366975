import { VFC } from "react";

import { Highlight } from "react-instantsearch-hooks-web";
import { Grid, Box, Image, Paragraph } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Column, Row } from "src/ui/box";
import { DestinationIcon } from "src/ui/icons";
import { Selectable } from "src/ui/selectable";

import { IntegrationBadge } from "../../integrations/integration-badge";

type DestinationHitProps = {
  category: string;
  definition: DestinationDefinition | null;
  onSelect: (definition: DestinationDefinition | null) => void;
  selected: boolean;
  hit: any; // Algolia doesn't expose this type to us, so we are leaving it untyped.
  onSendEvent: (eventType: string, hit: any, eventName?: string) => void;
};

export const DestinationHit: VFC<Readonly<DestinationHitProps>> = ({
  category,
  definition,
  onSelect,
  selected,
  hit,
  onSendEvent,
}) => {
  if (!definition) {
    return null;
  }

  const selectDefinition = (definition: DestinationDefinition, hit: any) => {
    onSendEvent("click", hit, "Destination Catalog Destination Clicked");
    analytics.track("Destination Catalog Destination Clicked", {
      destination_name: definition.name,
      destination_slug: definition.type,
      destination_status: definition.status,
      current_category: category,
    });

    if (definition.status === "alpha") {
      newIntercomMessage(
        `Hi, I would like to participate in the private preview for the ${definition.name} destination. My use case involves syncing _____ to ${definition.name}.`,
      );
    } else {
      onSelect(definition);
    }
  };

  return (
    <Selectable
      selected={selected}
      sx={{
        position: "relative",
        justifyContent: "start",
        px: 5,
        py: 3,
        bg: selected ? "primaries.0" : "inherit",
        border: "none",
        borderBottom: "small",
        borderRadius: 0,
        color: "base.9",
        overflow: "visible",
        ":hover": {
          boxShadow: "none",
          "& p, & p strong": {
            color: selected ? "inherit" : "primary",
          },
        },
      }}
      onSelect={() => selectDefinition(definition, hit)}
    >
      <Grid columns="repeat(7, 1fr)" gap={4}>
        <Row sx={{ alignItems: "center", gridColumn: "1/6" }}>
          {definition?.icon && (
            <Image
              src={`${definition?.icon}?h=40`}
              sx={{
                height: "20px",
                width: "20px",
                objectFit: "contain",
                flexShrink: 0,
              }}
            />
          )}
          {!definition?.icon && <DestinationIcon size={20} />}
          <Column sx={{ ml: 4 }}>
            <Paragraph
              sx={{
                fontSize: 1,
                fontWeight: 500,
                wordBreak: "break-word",
                color: "base.9",
                transition: "all 100ms ease-in-out",
                "& strong": {
                  color: "base.9",
                  bg: "primaries.1",
                  fontWeight: 600,
                },
              }}
            >
              <Highlight attribute="name" highlightedTagName="strong" hit={hit} />
            </Paragraph>
          </Column>
        </Row>
        <Box sx={{ gridColumn: "6" }}>
          <IntegrationBadge definition={definition} integrationType="destination" isSample={false} />
        </Box>
      </Grid>
    </Selectable>
  );
};
