import { ReactNode, useEffect, useState, VFC } from "react";

import { matchSorter } from "match-sorter";
import Creatable, { CreatableProps } from "react-select/creatable";

import { Props as CreatableMultiSelectProps } from "./creatable-multi-select";
import { SelectMenu } from "./menu";
import { MultiValue } from "./multi-value";
import { SelectOption } from "./option";
import { getSelectValue, selectStyles, Option } from "./select";
import { ValueContainer } from "./value";

export interface CreatableSelectProps
  extends Pick<
    CreatableProps<Option, boolean, never>,
    | "isLoading"
    | "isClearable"
    | "isValidNewOption"
    | "formatCreateLabel"
    | "formatOptionLabel"
    | "placeholder"
    | "onChange"
    | "onCreateOption"
  > {
  isMulti?: boolean;
  isError?: boolean;
  value: unknown | Option;
  options: Option[];
  disabled?: boolean;
  width?: string;
  empty?: ReactNode;
  reload?: unknown;
  tip?: ReactNode;
  noOptionsMessage?: (obj: { inputValue: string }) => string;
}

type CreatableSingleSelectProps = CreatableSelectProps & {
  onChange: (option: Option) => void;
};

type Props = CreatableSingleSelectProps | CreatableMultiSelectProps;

export const CreatableSelect: VFC<Props> = (props) => {
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const onInputChange = (value, { action }) => {
    if (action === "input-change") {
      const filteredOptions = matchSorter(props.options, value, {
        keys: ["label"],
      });
      setOptions(filteredOptions);
    } else if (action === "menu-close") {
      setOptions(props.options);
    }
  };

  const value =
    getSelectValue(props.value, options) ||
    (props.value ? ({ label: props.value, value: props.value } as Option) : props.value);

  return (
    <Creatable
      components={{ Option: SelectOption, ValueContainer, MultiValue, Menu: SelectMenu }}
      {...props}
      menuPlacement="auto"
      menuPortalTarget={document?.getElementById("portalAnchor")}
      options={options}
      styles={{
        ...selectStyles({
          size: "small",
          width: props.width || "100%",
          isError: props.isError,
          disabled: props.disabled,
          isMulti: props.isMulti,
        }),
      }}
      value={value as any}
      onInputChange={onInputChange}
    />
  );
};
