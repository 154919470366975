import { VFC } from "react";

import { Box, Card, Heading, Text } from "theme-ui";

import { WarningIcon } from "src/ui/icons";

type Props = {
  title: string;
  subtitle?: string;
  fullScreen?: boolean;
};

export const Warning: VFC<Props> = ({ title, subtitle, fullScreen }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: fullScreen ? 30 : 10,
      }}
    >
      <Card
        sx={{
          p: 12,
          width: "628px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WarningIcon color="base.4" size={48} />
        <Heading sx={{ fontWeight: "bold", my: 8, fontSize: "32px" }}>{title}</Heading>
        <Text sx={{ fontWeight: "semi", fontSize: 2, color: "base.4" }}>{subtitle || "It may have been deleted"}</Text>
      </Card>
    </Box>
  );
};
