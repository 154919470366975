import { useState, useEffect, VFC } from "react";

import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { Text, Box, Flex } from "theme-ui";

import GrafanaLogo from "src/components/logos/grafana.svg";
import KongLogo from "src/components/logos/kong.svg";
import SpringboardLogo from "src/components/logos/springboard.svg";
import { PricingCard } from "src/components/pricing/pricing-card";
import { useUser } from "src/contexts/user-context";
import { BillingOrganizationQuery, useCheckoutUrlQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Toggle } from "src/ui/toggle";

import { AddOrganizationNameModal } from "../modals/add-organization-name-modal";

type Organization = BillingOrganizationQuery["getBillingOrganization"];
interface Props {
  organization: Organization | undefined;
}

export const PricingDisplay: VFC<Readonly<Props>> = ({ organization }) => {
  const { workspace } = useUser();
  const { addToast } = useToasts();
  const client = useQueryClient();
  const [addOrgNameState, setAddOrgNameState] = useState<"closed" | "open" | "loading">("closed");
  const [selectedSku, setSelectedCta] = useState<string | undefined>();
  const [loadingSku, setLoadingSku] = useState<string | undefined>();
  const [isIntervalMonthly, setInterval] = useState(Boolean(organization?.plan?.sku?.endsWith("month")));

  const pricingPlans = getPricingPlans(isIntervalMonthly);

  useEffect(() => {
    setInterval(Boolean(organization?.plan?.sku?.endsWith("month")));
  }, [organization]);

  const toggleInterval = (value: boolean) => {
    analytics.track("Annual/Monthly Pricing Switched", {
      workspace_id: workspace?.id,
      viewing: value ? "monthly" : "yearly",
    });
    setInterval(value);
  };

  function ctaClick(sku: string) {
    analytics.track("Plan Checkout Clicked", {
      workspace_id: workspace?.id,
      sku,
    });
    const plan = pricingPlans.find((pricingPlan) => pricingPlan.sku === sku);
    if (plan?.ctaUrl) {
      return window.open(plan.ctaUrl, "_blank");
    }
    if (organization) {
      setLoadingSku(sku);
      return goToCheckout(sku, organization.name, () => {
        setLoadingSku(undefined);
      });
    } else {
      setSelectedCta(sku);
      return setAddOrgNameState("open");
    }
  }

  const goToCheckout = async (sku: string, orgName: string, fallback: () => void) => {
    const variables = {
      lookup_key: sku,
      workspace_id: workspace?.id,
      org_name: orgName,
    };

    analytics.track("Sending To Checkout", variables);

    try {
      const { getCheckoutUrl } = await client.fetchQuery(
        useCheckoutUrlQuery.getKey(variables),
        useCheckoutUrlQuery.fetcher(variables),
      );

      if (getCheckoutUrl.redirect) {
        location.href = getCheckoutUrl.redirect;
      } else {
        addToast("Can not generate checkout url", { appearance: "error", autoDismiss: false });
        analytics.track("Error Generating Checkout", variables);
        fallback();
      }
    } catch (e) {
      addToast(e.message, { appearance: "error", autoDismiss: false });
      analytics.track("Error Generating Checkout", variables);
      fallback();
    }
  };

  return (
    <>
      <Box sx={{ position: "relative", display: "flex", width: "100%", justifyContent: "space-between", py: 4, mb: 4 }}>
        <Heading sx={{ fontSize: 7, mt: 2 }} variant="h1">
          Choose a plan
        </Heading>
        <Row sx={{ fontSize: 2, alignItems: "center" }}>
          <Text
            sx={{
              color: isIntervalMonthly ? "base.4" : "secondary",
              mr: 3,
              display: "flex",
              justifyContent: "flex-end",
              textAlign: "right",
            }}
          >
            Annual&nbsp;<Text sx={{ color: isIntervalMonthly ? "base.4" : "indigos.1" }}>(save 20%)</Text>
          </Text>
          <Toggle
            sx={{
              display: "inline",
            }}
            value={isIntervalMonthly}
            onChange={toggleInterval}
          />
          <Text sx={{ color: isIntervalMonthly ? "secondary" : "base.4", ml: 3, textAlign: "left" }}>Monthly Pricing</Text>
        </Row>
      </Box>
      {/* pricing cards */}
      <Flex sx={{ gap: 6, justifyContent: "center", alignItems: "stretch", flexWrap: "wrap" }}>
        {pricingPlans?.map((card, index) => {
          return (
            <PricingCard
              key={index}
              checkList={card.checkList}
              checkListHeading={card.checkListHeading}
              ctaDisabled={card.sku === organization?.plan?.sku}
              ctaLoading={loadingSku === card.sku}
              ctaText={card.sku === organization?.plan?.sku ? "You are on this plan" : card.ctaText}
              ctaUrl={card.ctaUrl}
              isRecommended={card.isRecommended}
              partnerLogos={card.partnerLogos}
              plan={card.plan}
              price={card.price}
              priceHint={card.priceHint}
              priceUnit={card.priceUnit}
              sku={card.sku}
              sx={{ flex: "1", width: "280px" }}
              tooltipText={card.tooltipText}
              onCtaClick={ctaClick}
            />
          );
        })}
      </Flex>
      <AddOrganizationNameModal
        isLoading={addOrgNameState === "loading"}
        isOpen={addOrgNameState !== "closed"}
        onCancel={() => {
          analytics.track("Org Name Modal Canceled", {
            workspace_id: workspace?.id,
          });
          setAddOrgNameState("closed");
        }}
        onSubmit={(orgName) => {
          setAddOrgNameState("loading");
          analytics.track("Org Name Entered", {
            workspace_id: workspace?.id,
            orgName,
          });

          if (selectedSku) {
            goToCheckout(selectedSku, orgName, () => setAddOrgNameState("open"));
          }
        }}
      />
    </>
  );
};

export interface PricingPlan {
  plan: string;
  sku: string;
  price: string;
  priceUnit?: string;
  priceHint?: string;
  checkListHeading?: string;
  checkList: string[];
  partnerLogos: { img: string; alt: string }[];
  isRecommended?: boolean;
  ctaText?: string;
  ctaUrl?: string;
  tooltipText?: string;
}

export const getPricingPlans = (isIntervalMonthly: boolean): PricingPlan[] => [
  {
    plan: "Starter",
    sku: isIntervalMonthly ? "starter_plan_month" : "starter_plan_year",
    isRecommended: false,
    ctaText: "Select plan",
    price: isIntervalMonthly ? "$450" : "$350",
    priceUnit: isIntervalMonthly ? " / month" : " / month*",
    priceHint: isIntervalMonthly ? "" : "*Billed annually",
    checkListHeading: "Included:",
    checkList: [
      "Up to 2 destinations",
      "Unlimited destination fields",
      "Version control (git)",
      "Airflow, dbt and API triggers",
      "24h uptime SLA",
    ],
    partnerLogos: [{ img: GrafanaLogo, alt: "Grafana." }],
  },
  {
    plan: "Pro",
    sku: isIntervalMonthly ? "pro_plan_month" : "pro_plan_year",
    isRecommended: true,
    ctaText: "Select plan",
    price: isIntervalMonthly ? "$1,000" : "$800",
    priceUnit: isIntervalMonthly ? " / month" : " / month*",
    priceHint: isIntervalMonthly ? "" : "*Billed annually",
    checkListHeading: "Included:",
    checkList: [
      "Up to 4 destinations",
      "Unlimited destination fields",
      "Version control (git)",
      "Airflow, dbt and API triggers",
      "24h uptime SLA",
    ],
    partnerLogos: [{ img: SpringboardLogo, alt: "Springboard." }],
  },
  {
    sku: "",
    plan: "Business",
    isRecommended: false,
    ctaText: "Talk to us",
    ctaUrl: "https://calendly.com/hightouch-experts/30min",
    price: "Custom Pricing",
    checkListHeading: "All of Pro, plus:",
    checkList: [
      "Scalable, usage-based pricing",
      "Unlimited destination fields",
      "Custom storage integration",
      "Audience builder (add-on)",
      "SSO, permissions, and access control",
      "Premium destinations: Netsuite and SFMC",
      "Professional services and advanced SLAs",
    ],
    partnerLogos: [{ img: KongLogo, alt: "Kong." }],
  },
];

export const pricingPlans = [...getPricingPlans(false), ...getPricingPlans(true)];
