import { VFC } from "react";

import { useClearRefinements, useMenu } from "react-instantsearch-hooks-web";
import { Text, Grid } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";

type Props = {
  currentCategory: string;
  attribute: string;
  query: string;
};

export const CategoryMenu: VFC<Props> = ({ currentCategory, attribute, query }) => {
  const menuApi = useMenu({
    attribute,
    limit: 30,
    sortBy: ["name:asc"],
  });
  const { refine: clearRefinements } = useClearRefinements();

  return (
    <Grid gap={1}>
      <Selectable
        selected={currentCategory === "All"}
        sx={{
          px: 2,
          py: 1,
          borderRadius: 1,
          fontSize: 1,
          fontWeight: "bold",
          cursor: "pointer",
          color: currentCategory === "All" ? "primary" : "base.5",
          bg: currentCategory === "All" ? "primaries.0" : undefined,
          border: "none",
          ":hover": {
            color: currentCategory === "All" ? undefined : "primary",
          },
        }}
        onSelect={() => {
          clearRefinements();
          analytics.track("Destination Catalog Category Clicked", { current_category: "All" });
        }}
      >
        All
      </Selectable>
      {menuApi?.items?.map((filter) => {
        return (
          <Selectable
            key={filter.label}
            selected={currentCategory === filter.label}
            sx={{
              px: 2,
              py: 1,
              borderRadius: 1,
              fontSize: 1,
              fontWeight: "bold",
              cursor: "pointer",
              color: currentCategory === filter.label ? "primary" : "base.5",
              bg: currentCategory === filter.label ? "primaries.0" : undefined,
              border: "none",
              flexDirection: "row",
              gap: 1,
              ":hover": {
                color: currentCategory === filter.label ? undefined : "primary",
              },
            }}
            onSelect={() => {
              menuApi?.refine(filter.value);
              analytics.track("Destination Catalog Category Clicked", { current_category: filter.label });
            }}
          >
            {filter.label}
            {query !== "" && <Text sx={{ fontWeight: "semi" }}>{`(${filter.count})`}</Text>}
          </Selectable>
        );
      })}
    </Grid>
  );
};
