import { FC } from "react";

import { default as LoadingSkeleton } from "react-loading-skeleton";
import { Spinner as ThemedSpinner, Flex, ThemeUIStyleObject } from "theme-ui";
import "react-loading-skeleton/dist/skeleton.css";

export const Skeleton = LoadingSkeleton;

export interface SpinnerProps {
  size?: number;
  color?: string;
  sx?: ThemeUIStyleObject;
}

export const Spinner: FC<Readonly<SpinnerProps>> = ({ size = 24, color = "primary", sx = {} }) => (
  <ThemedSpinner size={size} sx={{ color, ...sx }} />
);

export const PageSpinner = () => (
  <Flex sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
    <Spinner size={64} />
  </Flex>
);
