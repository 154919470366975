import { VFC } from "react";

import { SourceDefinition } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { WizardOnboarding } from "src/ui/wizard";

import { useCreateSourceWizard, NewSource } from "../sources/use-create-source-wizard";

type Props = {
  definition?: SourceDefinition;
  sourceSetup: "declaration" | "connect" | "all";
  onCancel(): void;
  onSubmit(args: NewSource): void;
};

export const OnboardingCreateSource: VFC<Readonly<Props>> = ({
  definition: previousSourceDefinition,
  sourceSetup,
  onCancel,
  onSubmit,
}) => {
  const { createSource, loading, sourceDefinition, setStep, step, steps } = useCreateSourceWizard({
    previousSourceDefinition,
    onSubmit,
    onConnectClick: (definition) => {
      localStorage.setItem("onboardingSourceOauth", definition.type);
    },
  });

  const setSourceDeclaration = () => {
    onSubmit({ definition: sourceDefinition });
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <WizardOnboarding
      setStep={setStep}
      step={step}
      steps={sourceSetup === "declaration" && steps[0] ? [steps[0]] : steps}
      title={sourceSetup === "declaration" ? "Select source" : "Connect source"}
      onCancel={onCancel}
      onSubmit={sourceSetup === "declaration" ? setSourceDeclaration : createSource}
    />
  );
};
